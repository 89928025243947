import React, { Component } from 'react'
import styled from 'styled-components'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import { media } from '../styles/media'

const Section = styled.section`
  background-color: #ebeae4;
  .bg-slide {
  }
  .slick-list {
    padding: 0 6% 0 0 !important;
    @media ${media.mdUp} {
      padding: 0 !important;
    }
  }
  .slick-slider {
    overflow-x: hidden;
    .slick-track {
      display: flex !important;
    }
    .slick-slide {
      height: inherit !important;
      background: #f3f2ed;
      border-radius: 32px;
      margin-right: 1.5rem;
      @media ${media.mdUp} {
        background: none;
        border-radius: 0;
        margin-right: 0;
      }
    }
  }
  .slick-dots {
    position: relative;
    bottom: 12px;
    text-align: left;
    display: none !important;
    @media ${media.mdUp} {
      display: block !important;
      text-align: right;
      right: 60px;
    }
    button {
      display: none !important;
    }
    li {
      cursor: pointer;
      width: 12px;
      height: 2px;
      display: inline-block;
      background: #292524;
      opacity: 0.2;
      &.slick-active {
        opacity: 1;
      }
    }
  }
  .slick-prev {
    display: none !important;
  }
  .slick-next {
    top: auto;
    bottom: -20px;
    right: 0;
    width: 40px;
    height: 40px;
    z-index: 200;
    display: none !important;
    @media ${media.mdUp} {
      display: block !important;
    }
  }
  .slick-prev:before,
  .slick-next:before {
    width: 37px;
    height: 37px;
    display: block;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg width='36' height='37' viewBox='0 0 36 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='36' y='36.4453' width='36' height='36' rx='18' transform='rotate(-180 36 36.4453)' fill='%23F3F2ED'/%3E%3Cg opacity='0.98'%3E%3Cg clip-path='url(%23clip0_0_3238)'%3E%3Cpath d='M10.5 19.3828L21.9094 19.3828L16.6687 24.6234L18 25.9453L25.5 18.4453L18 10.9453L16.6781 12.2672L21.9094 17.5078L10.5 17.5078L10.5 19.3828Z' fill='%23373736'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_0_3238'%3E%3Crect width='22.5' height='22.5' fill='white' transform='translate(29.25 29.6953) rotate(-180)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    content: '';
  }
`

class BlockTestimonials extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 720,
          settings: {
            centerMode: true,
            centerPadding: '3%',
          },
        },
      ],
    }

    const { primary, items } = this.props.slice
    return (
      <Section className="[ BlockTestimonials ] mt-16 md:mt-24 py-16 md:py-24">
        <div id="testimonials" className="md:container ml-6 md:m-auto">
          <Slider {...settings}>
            {items.map((item, index) => (
              <div key={`testimonail-${index}`}>
                <div className="grid grid-cols-12 p-6 md:p-0 radius-32 bg-slide height-full">
                  <div className="col-span-12 md:col-span-9 md:pr-10">
                    <blockquote className="text-lg lg:text-2xl">&ldquo;{item.quote}&rdquo;</blockquote>
                  </div>
                  <div className="col-span-12 md:col-span-3 mt-4 md:mt-0">
                    <div className="flex items-center md:justify-end">
                      <div className="flex-auto">
                        <div className="rounded-full overflow-hidden !w-14 !h-14">
                          <img
                            className="object-cover object-center max-w-none w-full h-full"
                            src={item.image.url}
                            alt={item.image.alt}
                          />
                        </div>
                      </div>
                      <div className="pl-4 flex-auto">
                        <p className="text-lg font-medium">{item.heading}</p>
                        <p className="opacity-80">{item.position}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </Section>
    )
  }
}
export default BlockTestimonials
