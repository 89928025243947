import * as React from 'react'
import { graphql } from 'gatsby'

import Seo from '../components/seo'
import SliceZone from '../components/SliceZone'
import BlockHomeStories from '../components/BlockHomeStories'
// import BlockJobs from '../components/BlockJobs'
import BlockStory from '../components/BlockStory'

import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

const Blog = ({ data, pageContext, path }) => {

  let doc, blogs, global

  if (!data.blog) {    
    doc = data.prismicBlog.data
  } else {
    doc = data.blog.edges[0].node.data
    blogs = data.allblogs
    global = data.global.edges[0].node.data
  }

  return (
    <>
      <Seo
        title={doc.title.text}
        description={doc.description}
        keywords={doc.keywords}
        data={doc}
      />
      <BlockStory data={doc} global={global} blogs={blogs} />
      {doc.page === 'overview' || doc.page === 'home' ? (
        <BlockHomeStories title="Stories" size="h1" columns="6" />
      ) : (
        ''
      )}
      {/* {doc.page === 'careers' ? <BlockJobs /> : ''} */}
    </>
  )
}

export const query = graphql`
  query Blogs($slug: String!) {
    global: allPrismicGlobalVariables {
      edges {
        node {
          _previewable
          data {
            blog_button
            blog_button_url
            blog_eyebrow
            blog_heading
          }
        }
      }
    }
    allblogs: allPrismicBlog(
      limit: 3
      sort: { order: DESC, fields: data___date }
      filter: { data: { slug: { ne: $slug } } }
    ) {
      edges {
        node {
          _previewable
          data {
            slug
            date(formatString: "MMM D, YYYY")
            description
            body {
              ... on PrismicBlogDataBodyDivider {
                id
                slice_label
                slice_type
                primary {
                  divider
                }
              }
              ... on PrismicBlogDataBodyImageBlock {
                id
                slice_label
                slice_type
                primary {
                  image_title
                  image1 {
                    alt
                    url
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  }
                }
              }
              ... on PrismicBlogDataBodyRichText {
                id
                slice_label
                slice_type
                primary {
                  content {
                    html
                    text
                    richText
                  }
                }
              }
              ... on PrismicBlogDataBodyTitle {
                id
                slice_label
                slice_type
                primary {
                  title1 {
                    html
                    richText
                    text
                  }
                }
              }
            }
            title {
              html
              richText
              text
            }
            image {
              url
              alt
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
            published_by_image {
              url
              alt
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
            published_by
            author
            tag
          }
        }
      }
    }
    blog: allPrismicBlog(filter: { data: { slug: { eq: $slug } } }) {
      edges {
        node {
          _previewable
          data {
            slug
            date(formatString: "MMM D, YYYY")
            description
            body {
              ... on PrismicBlogDataBodyDivider {
                id
                slice_label
                slice_type
                primary {
                  divider
                }
              }
              ... on PrismicBlogDataBodyImageBlock {
                id
                slice_label
                slice_type
                primary {
                  image_title
                  image1 {
                    alt
                    url
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  }
                }
              }
              ... on PrismicBlogDataBodyRichText {
                id
                slice_label
                slice_type
                primary {
                  content {
                    html
                    text
                    richText
                  }
                }
              }
              ... on PrismicBlogDataBodyTitle {
                id
                slice_label
                slice_type
                primary {
                  title1 {
                    html
                    richText
                    text
                  }
                }
              }
            }
            title {
              html
              richText
              text
            }
            published_by_image {
              url
              alt
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
            published_by
            image {
              url
              alt
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
            author
            tag
          }
        }
      }
    }
  }
`

export default withPrismicPreview(Blog)