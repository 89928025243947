import React, { Component, useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { gsap } from 'gsap'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import logo from '../images/the-routing-company.svg'
import arrow from '../images/circle-arrow-dark.svg'
import check from '../images/circle-check.svg'
import arrowDark from '../images/arrow--newsletter.svg'
import Parser from 'rss-parser/dist/rss-parser.min.js';

const Hero = styled.header`
  .newsletter__input {
    background: none;
    font-size: 16px;
    line-height: 110%;
    letter-spacing: -0.01em;
    border: 1px solid rgba(0, 0, 0, 0.1);
    height: 44px;
    border-radius: 36px;
    padding: 3px 5px 0 15px;
    transition: all 0.3s ease-in;
    &:focus {
      border-color: rgba(0, 0, 0, 0.5);
      outline: 0;
    }
  }
  form button {
    transition: all 0.3s ease-in;
  }
  form button[disabled] {
    opacity: 0.5;
  }
  input[type='radio']:checked + label span,
  input[type='radio']:checked + label span:hover,
  input[type='radio']:checked + label:hover span {
    background-color: #373736;
    border-color: #373736;
  }
  input[type='radio'] + label span:hover,
  input[type='radio'] + label:hover span {
    background-color: rgba(55, 55, 54, 0.05);
  }
`

export default function BlockMailchimp({ slice, data }) {
    const [mcItems, setMcItems] = useState(null)

    const getMailChimp = async () => {
      const options = {
        method: 'GET',
        // beware of no-cors: https://stackoverflow.com/a/40182952 - use a proxy instead
        // mode: 'no-cors',
        headers: {
            'Content-Type': 'text/javascript'
        },
        cache: 'no-cache'
      };
      const response = await fetch(`https://knockknock.thehatchery.workers.dev/?https://theroutingcompany.us13.list-manage.com/generate-js/?u=a4da16d11b719a3278222750c&show=10&fid=124014`, options);
      console.log('response', response);
      let data = await response.text();
      data = data.replace('document.write("','');
      data = data.replace('");', '');

      data = data.replace(
        /\\u([0-9a-f]{4})/g, 
        (whole, group1) => {
            return String.fromCharCode(parseInt(group1, 16));
        }
      );

      data = data.replace(/\\/g, '');
      console.log('data', data);
      setMcItems({ __html: data });
    }

    useEffect(() => {
        const fadeElements = gsap.timeline()
        fadeElements.to('#block-hero', {
          opacity: 1,
          delay: 0.25,
        })
        fadeElements.from('#block-hero .fade-up', {
          duration: 0.7,
          opacity: '0',
          y: '20%',
          stagger: {
            amount: '0.5',
          },
        })

        document.getElementById('radio1').checked = true


        getMailChimp()

  }, [])

  const [filter, setFilter] = useState('all')

  const { primary } = slice

    // console.log(slice)

  const [mcResponse, setMcResponse] = useState({ email: '', result: null })
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [formShow, setFormShow] = useState(false)

  const handleClick = () => {
    setFormShow(!formShow)
  }

  const handleChange = event => {
    const emailInput = event.target.value
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!emailRegex.test(emailInput)) {
      setSubmitDisabled(true)
    } else {
      setSubmitDisabled(false)
    }
  }

  const handleNewsletterSubmit = async event => {
    setMcResponse({})
    event.preventDefault()
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const formData = new FormData(event.target)

    const emailError = event.target.querySelector('.form__error')
    const emailErrorSubscribed = event.target.querySelector(
      '.form__error-subscribed'
    )
    const emailInput = event.target.querySelector('.newsletter__input')

    if (!emailRegex.test(emailInput.value)) {
      emailError.style.display = 'flex'
      setTimeout(() => {
        emailError.style.display = 'none'
      }, 3000)
    } else {
      // console.log(formData.get('tags'))

      const response = await addToMailchimp(formData.get('email'), {
        ...(document.querySelector('#radio1:checked') !== null && {
          'group[699683][1]': '1',
        }),
        ...(document.querySelector('#radio2:checked') !== null && {
          'group[699683][2]': '2',
        }),
        ...(document.querySelector('#radio3:checked') !== null && {
          'group[699683][4]': '4',
        }),
      })
      setMcResponse(response)
      updateForms(emailInput, emailError, emailErrorSubscribed, response)
    }
  }

  const updateForms = (
    emailInput,
    emailError,
    emailErrorSubscribed,
    response
  ) => {
    // SUCCESS STATE
    if (response.result === 'success') {
      emailInput.value = "You're on the list!"
      setTimeout(() => {
        emailInput.value = ''
      }, 3000)
      setFormShow(false)

      // ERROR STATE
    } else if (response.result === 'error') {
      const responseMessage = String(response.msg)
      const alreadySubscribed = 'already subscribed'
      if (responseMessage.includes(alreadySubscribed)) {
        // IF ON DESKTOP
        if (matches) {
          emailErrorSubscribed.style.display = 'flex'
          setTimeout(() => {
            emailErrorSubscribed.style.display = 'none'
          }, 3000)

          // IF ON MOBILE
        } else {
          emailInput.value = "You're already subscribed!"
          setTimeout(() => {
            emailInput.value = ''
          }, 3000)
        }
      } else if (matches) {
        emailError.style.display = 'flex'
        setTimeout(() => {
          emailError.style.display = 'none'
        }, 3000)
      } else {
        emailInput.value = 'Submission Error'
        setTimeout(() => {
          emailInput.value = ''
        }, 3000)
      }
    }
  }

  return (
    <>
      <Hero id="block-hero" className="container relative">
        <div className="relative pt-10 md:pt-16 mb-6 md:mb-20">
          <div className="grid grid-cols-12 relative z-10">
            <div className="col-span-12 md:col-span-10">
              <h1 className="h1 md:mb-10 color--primary fade-up">
                Newsletter
              </h1>
            </div>
            <div className="col-span-2 hidden md:block">
              <div className="flex justify-end">
                <img
                  src={logo}
                  alt="The Routing Company"
                  className="fade-up logo"
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 relative z-10 pb-10 border-b border-b-black-300">
            <div className="col-span-12 md:col-span-5 relative z-10">
                <p className="text-lg fade-up">Welcome to our newsletter, "Moving The World". Every few weeks, we provide the latest from The Routing Company.</p>

              <div className="col-span-3 col-start-1 md:col-span-3 md:col-start-8 lg:col-start-8 mt-12 md:mt-0 relative fade-up">
                {mcResponse.result === 'success' ? (
                  <button
                    className="mt-10 btn--text--subscribe flex items-center"
                    onClick={handleClick}
                  >
                    <div>Roger that, you're now subscribed</div>
                    <img src={check} className="ml-2" alt="" loading="lazy" />
                  </button>
                ) : (
                  <button
                    className="mt-10 btn--text--subscribe flex items-center"
                    onClick={handleClick}
                  >
                    <div>Subscribe to updates</div>
                    <img src={arrow} className="ml-2" alt="" loading="lazy" />
                  </button>
                )}
                {mcResponse.result === 'success' ? (
                  <></>
                ) : (
                  <form
                    onSubmit={handleNewsletterSubmit.bind(this)}
                    className={`${
                      formShow ? '' : 'hidden'
                    } absolute z-10 w-full md:w-4/5 mt-3 md:mt-6 bg--main p-6 box-shadow rounded-2xl`}
                  >
                    <div className="flex items-center mr-4 mb-4">
                      <input
                        id="radio1"
                        type="radio"
                        name="tags"
                        className="hidden"
                        value="1"
                      />
                      <label
                        htmlFor="radio1"
                        className="flex items-center cursor-pointer"
                      >
                        <span className="w-6 h-6 inline-block mr-1 rounded-full border border-black" />
                        <div className="text-lg ml-2">
                          Subscribe to all updates
                        </div>
                      </label>
                    </div>

                    <div className="flex items-center mr-4 mb-4">
                      <input
                        id="radio2"
                        type="radio"
                        name="tags"
                        className="hidden"
                        value="2"
                      />
                      <label
                        htmlFor="radio2"
                        className="flex items-center cursor-pointer"
                      >
                        <span className="w-6 h-6 inline-block mr-1 rounded-full border border-black" />
                        <div className="text-lg ml-2">Newsletters</div>
                      </label>
                    </div>

                    <div className="flex items-center mr-4 mb-4">
                      <input
                        id="radio3"
                        type="radio"
                        name="tags"
                        className="hidden"
                        value="4"
                      />
                      <label
                        htmlFor="radio3"
                        className="flex items-center cursor-pointer"
                      >
                        <span className="w-6 h-6 inline-block mr-1 rounded-full border border-black" />
                        <div className="text-lg ml-2">Press releases</div>
                      </label>
                    </div>

                    <div className="newsletter__input-container items-center w-full">
                      <div className="relative">
                        <input
                          onChange={handleChange}
                          type="email"
                          name="email"
                          required
                          className="newsletter__input w-full"
                          placeholder="Enter your email"
                          autoComplete="chrome-off"
                        />
                        <button
                          disabled={!!submitDisabled}
                          className="newsletter__submit absolute top-1 right-1 hover:opacity-90"
                        >
                          <img src={arrowDark} alt="" loading="lazy" />
                        </button>
                      </div>
                    </div>

                    <div className="form__error hidden font-serif lowercase block text-2xs text-red pt-3">
                      <p>Please enter a valid email address</p>
                    </div>
                    <div className="form__error form__error-subscribed hidden font-serif lowercase block text-2xs text-red pt-3">
                      <p>This email is already subscribed!</p>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </Hero>
      <section className="container relative mb-20">
        <div className="grid grid-cols-12 md:gap-x-20">
          <div className="col-span-12 md:col-span-7">
              
              { mcItems &&
                <div dangerouslySetInnerHTML={mcItems} />
              }

              { !mcItems &&
                <p>Loading...</p>
              }
              
          </div>
        </div>
      </section>
    </>
  )
}
