exports.linkResolver = (doc) => {
  if (doc.type === 'blog' && doc.slug) {
    const slugParts = doc.slug.split("newsroom");
    return `/newsroom/${slugParts[1]}`;
  }

  // URL for a page type
  if (doc.type === 'page' && doc.slug) {
    return `/${doc.slug}`;
  }

  // Backup for all other types
  return '/';
};
