import React, { Component } from 'react'
import styled from 'styled-components'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import arrow from '../images/circle-arrow-dark.svg'
import linkedin from '../images/svg--linkedin.svg'
import close from '../images/close.svg'
import BlockLeadershipBio from './BlockLeadershipBio'
import { PrismicRichText } from '@prismicio/react'

class BlockLeadership extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      popup: {
        heading: '',
        content: ''
      }
    }
  }

  toggleModal = (val) => {
    const html = document.querySelector('html')
    const navHolder = document.querySelector('.nav--holder')
    const htmlActive = document.querySelector('html.is-scroll-disabled')


    // close popup
    if (htmlActive) {
      this.setState({ active: false })
      html.classList.remove('is-scroll-disabled')

    // open popup
    } else {
      this.setState(val)

      navHolder.classList.remove('nav-down')

      setTimeout(() => {
        html.classList.add('is-scroll-disabled')
        navHolder.classList.add('nav-up')
      }, 500)

    }
  }

  closeModal = (e) => {
    const html = document.querySelector('html')
    if( !e.target.classList.contains('modal-content') ) {
      this.setState({ active: false })
      html.classList.remove('is-scroll-disabled')
    }
  }

  render() {
    const { primary, items } = this.props.slice

    return (
      <React.Fragment>
        <section className="container relative md:pt-10">
          <div className="grid grid-cols-12 md:mb-10">
            <div className="col-span-12 md:col-span-2">
              <span className="eyebrow-name fade-up">{ primary.section_title }</span>
            </div>
          </div>
          <div className="grid grid-cols-12 md:gap-x-5">
            {items.map((item, index) => (
              <BlockLeadershipBio item={item} key={index} columns={primary.columns} toggleModal={this.toggleModal} />
            ))}
          </div>
        </section>
        <div
          className={`${
            this.state.active ? 'is-active' : ''
          } modal p-4`}
          onClick={this.closeModal}
        >
          <div className="modal-inner overflow-y-auto w-full h-full">
            <div className="pt-6">
              <div className="text-right">
                <a onClick={this.toggleModal} className="inline-block">
                  <img src={close} alt="Close Modal" loading="lazy" />
                </a>
              </div>
            </div>
            <div className="modal-content bg-white max-w-xl w-11/12 p-4 md:p-8 radius-32 mx-auto">
              <div className="pointer-events-none">
                <p className="font-medium text-lg">{this.state.popup.heading}</p>
                <hr className="my-5" />
                <PrismicRichText field={this.state.popup.content} />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
export default BlockLeadership
