import React, { Component } from 'react'
import styled from 'styled-components'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import arrow from '../images/circle-arrow-dark.svg'
import linkedin from '../images/svg--linkedin.svg'

class BlockLeadershipBio extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      popup: false
    }
  }

  render() {
    const { item, columns } = this.props

    const columnSpan = columns == 3 ? 4 : 3

    return (
      <div className={columns ? `col-span-12 md:col-span-${columnSpan}` : `col-span-12 md:col-span-4`}>
        <div
          className={`${
            this.state.active && 'active'
          } block mb-10 relative overflow-hidden bio`}
        >
          { item.link.url &&
            <a href={item.link.url}>
              <img src={linkedin} alt="" className="absolute top-4 left-4 z-10" loading="lazy" />
            </a>
          }
          <GatsbyImage
            image={item.image.gatsbyImageData}
            alt={item.image.alt || `Photo of ${item.heading}.`}
            className="radius-tl-32 block"
          />

          { /* Short bios
              - use content field
              - expand open on page
          */ }
          { item.content.text &&
            <div
              onClick={() => this.setState({ active: !this.state.active })}
              className="radius-tl-32 pt-5 pl-5 pr-5 bg--main absolute z-10 bio--content w-full"
            >
              <div className="flex relative justify-between items-center">
                <div>
                  { item.position &&
                    <p>{item.position}</p>
                  }
                  <p className="font-medium">{item.heading}</p>
                </div>

                <img src={arrow} alt="" loading="lazy" />
              </div>
              <div className="bio--content--hide">
                <hr className="my-5" />
                <p>{item.content.text}</p>
              </div>
            </div>
          }

          { /* Long bios
              - use modal content field
              - open in modal popup
          */ }
          { item.modal_content.text &&
            <div
              onClick={() => this.props.toggleModal({ active: true, popup: { heading: item.heading, content: item.modal_content.richText } })}
              className="radius-tl-32 pt-5 pl-5 pr-5 bg--main absolute z-10 bio--content w-full"
            >
              <div className="flex relative justify-between items-center">
                <div>
                  { item.position &&
                    <p>{item.position}</p>
                  }
                  <p className="font-medium">{item.heading}</p>
                </div>

                <img src={arrow} alt="" loading="lazy" />
              </div>
            </div>
          }

        </div>
      </div>
    )
  }
}
export default BlockLeadershipBio
