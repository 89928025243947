import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import arrowDark from '../images/arrow--newsletter.svg'


const Section = styled.section`

  &.fixed {
    .page-nav-wrapper {
      background: var(--color-bg);
      border-bottom: 1px solid var(--grey-2);
      padding: .8rem 0;
      position: fixed;
      top: 0;
      z-index: 99;
    }
  }

  a {
    text-decoration: none;
    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  #pageNavList{
    &::-webkit-scrollbar {
      display: none;
    }
  }

  #pnProductNav {
    font-size: 0;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &::-webkit-scrollbar {
      display: none;
    }

    &[data-overflowing="right"],
    &[data-overflowing="both"] {
      ~ #pnAdvancerRight {
        opacity: 1;
      }
    }
    &[data-overflowing="left"],
    &[data-overflowing="both"] {
      ~ #pnAdvancerLeft {
        opacity: 1;
      }
    }
  }

  .pn-ProductNav_Contents-no-transition {
    transition: none;
  }

  #pnAdvancerLeft {
    &:before {
      background:linear-gradient(270deg, #F3F2ED 0%, rgba(243, 242, 237, 0) 100%);
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 100px;
      height: 100%;
      z-index: 0;
    }
  }
  #pnAdvancerRight {
    &:before {
      background:linear-gradient(270deg, #F3F2ED 0%, rgba(243, 242, 237, 0) 100%);
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 100px;
      height: 100%;
      z-index: 0;
    }
  }
`

const slugify = str =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');


const titlefy = str => {
  const titles = str.split(' - ')
  return titles[0]
}

export default function BlockPageNav({ slice, page, title, index }) {

  useEffect(() => {

    /*
    Fix nav on scroll
    */
    let lastScrollTop = 0
    const delta = 5
    const navHolder = document.querySelector('.page-nav')

    if( !navHolder ) {
      return
    }

    const navHolderOffset = navHolder.getBoundingClientRect()
    const navHolderTop = navHolderOffset.top

    function hasNavScrolled() {
      const st = document.documentElement.scrollTop

      if (Math.abs(lastScrollTop - st) <= delta) return

      if ( st > navHolderTop) {
        navHolder.classList.add('fixed')
      } else {
        navHolder.classList.remove('fixed')
      }

      lastScrollTop = st
    }

    window.addEventListener('scroll', hasNavScrolled)


    /*
    Pill nav pager
    */

    const SETTINGS = {
      navBarTravelling: false,
      navBarTravelDirection: "",
      navBarTravelDistance: 150
    }

    const pnAdvancerLeft = document.getElementById("pnAdvancerLeft")
    const pnAdvancerRight = document.getElementById("pnAdvancerRight")
    const pnProductNav = document.getElementById("pnProductNav")
    const pnProductNavContents = document.getElementById("pnProductNavContents")

    pnProductNav.setAttribute("data-overflowing", determineOverflow(pnProductNavContents, pnProductNav))

    // Handle the scroll of the horizontal container
    let last_known_scroll_position = 0;
    let ticking = false;

    function doSomething(scroll_pos) {
        pnProductNav.setAttribute("data-overflowing", determineOverflow(pnProductNavContents, pnProductNav))
    }

    pnProductNav.addEventListener("scroll", function() {
      last_known_scroll_position = window.scrollY;
      if (!ticking) {
        window.requestAnimationFrame(function() {
          doSomething(last_known_scroll_position);
          ticking = false;
        });
      }
      ticking = true;
    })

    // Prev btn
    pnAdvancerLeft.addEventListener("click", function() {
      // If in the middle of a move return
        if (SETTINGS.navBarTravelling === true) {
            return;
        }
        // If we have content overflowing both sides or on the left
        if (determineOverflow(pnProductNavContents, pnProductNav) === "left" || determineOverflow(pnProductNavContents, pnProductNav) === "both") {
            // Find how far this panel has been scrolled
            const availableScrollLeft = pnProductNav.scrollLeft;
            // If the space available is less than two lots of our desired distance, just move the whole amount
            // otherwise, move by the amount in the settings
            if (availableScrollLeft < SETTINGS.navBarTravelDistance * 2) {
                pnProductNavContents.style.transform = "translateX(" + availableScrollLeft + "px)";
            } else {
                pnProductNavContents.style.transform = "translateX(" + SETTINGS.navBarTravelDistance + "px)";
            }
            // We do want a transition (this is set in CSS) when moving so remove the class that would prevent that
            pnProductNavContents.classList.remove("pn-ProductNav_Contents-no-transition");
            // Update our settings
            SETTINGS.navBarTravelDirection = "left";
            SETTINGS.navBarTravelling = true;
        }
        // Now update the attribute in the DOM
        pnProductNav.setAttribute("data-overflowing", determineOverflow(pnProductNavContents, pnProductNav));
    });

    // Next btn
    pnAdvancerRight.addEventListener("click", function() {
        // If in the middle of a move return
        if (SETTINGS.navBarTravelling === true) {
            return;
        }
        // If we have content overflowing both sides or on the right
        if (determineOverflow(pnProductNavContents, pnProductNav) === "right" || determineOverflow(pnProductNavContents, pnProductNav) === "both") {
            // Get the right edge of the container and content
            const navBarRightEdge = pnProductNavContents.getBoundingClientRect().right;
            const navBarScrollerRightEdge = pnProductNav.getBoundingClientRect().right;
            // Now we know how much space we have available to scroll
            const availableScrollRight = Math.floor(navBarRightEdge - navBarScrollerRightEdge);
            // If the space available is less than two lots of our desired distance, just move the whole amount
            // otherwise, move by the amount in the settings
            if (availableScrollRight < SETTINGS.navBarTravelDistance * 2) {
                pnProductNavContents.style.transform = "translateX(-" + availableScrollRight + "px)";
            } else {
                pnProductNavContents.style.transform = "translateX(-" + SETTINGS.navBarTravelDistance + "px)";
            }
            // We do want a transition (this is set in CSS) when moving so remove the class that would prevent that
            pnProductNavContents.classList.remove("pn-ProductNav_Contents-no-transition");
            // Update our settings
            SETTINGS.navBarTravelDirection = "right";
            SETTINGS.navBarTravelling = true;
        }
        // Now update the attribute in the DOM
        pnProductNav.setAttribute("data-overflowing", determineOverflow(pnProductNavContents, pnProductNav));
    });

    // Scroll contents
    pnProductNavContents.addEventListener(
        "transitionend",
        function() {
            // get the value of the transform, apply that to the current scroll position (so get the scroll pos first) and then remove the transform
            const styleOfTransform = window.getComputedStyle(pnProductNavContents, null);
            const tr = styleOfTransform.getPropertyValue("-webkit-transform") || styleOfTransform.getPropertyValue("transform");
            // If there is no transition we want to default to 0 and not null
            const amount = Math.abs(parseInt(tr.split(",")[4]) || 0);
            pnProductNavContents.style.transform = "none";
            pnProductNavContents.classList.add("pn-ProductNav_Contents-no-transition");
            // Now lets set the scroll position
            if (SETTINGS.navBarTravelDirection === "left") {
                pnProductNav.scrollLeft = pnProductNav.scrollLeft - amount;
            } else {
                pnProductNav.scrollLeft = pnProductNav.scrollLeft + amount;
            }
            SETTINGS.navBarTravelling = false;
        },
        false
    );

    // Handle setting the currently active link
    // pnProductNavContents.addEventListener("click", function(e) {
    //   var links = [].slice.call(document.querySelectorAll(".pn-ProductNav_Link"));
    //   links.forEach(function(item) {
    //     item.setAttribute("aria-selected", "false");
    //   })
    //   e.target.setAttribute("aria-selected", "true");
    //   moveIndicator(e.target);
    // });

    // function moveIndicator(item, color) {
    //     const textPosition = item.getBoundingClientRect();
    //     const container = pnProductNavContents.getBoundingClientRect().left;
    //     const distance = textPosition.left - container;
    //     pnIndicator.style.transform = "translateX(" + (distance + pnProductNavContents.scrollLeft) + "px) scaleX(" + textPosition.width * 0.01 + ")";
    //     if (color) {
    //         pnIndicator.style.backgroundColor = colour;
    //     }
    // }

    function determineOverflow(content, container) {
        const containerMetrics = container.getBoundingClientRect();
        const containerMetricsRight = Math.floor(containerMetrics.right);
        const containerMetricsLeft = Math.floor(containerMetrics.left);
        const contentMetrics = content.getBoundingClientRect();
        const contentMetricsRight = Math.floor(contentMetrics.right);
        const contentMetricsLeft = Math.floor(contentMetrics.left);
       if (containerMetricsLeft > contentMetricsLeft && containerMetricsRight < contentMetricsRight) {
            return "both";
        } else if (contentMetricsLeft < containerMetricsLeft) {
            return "left";
        } else if (contentMetricsRight > containerMetricsRight) {
            return "right";
        } else {
            return "none";
        }
    }

  });

  const toggleMobileDropdown = (e) => {
    const btn = e.target
    const target = btn.getAttribute('aria-controls')
    const $target = document.getElementById(target)
    $target.classList.toggle('hidden')
  }

  const updateActive = (index) => {
    const pageNav = document.getElementById(`page-nav-${index}`)
    console.log(`pageNav`, pageNav)
    //pageNav.classList.add('hovering')
  }

  const resetActive = (index) => {
    const pageNav = document.getElementById(`page-nav-${index}`)
    //pageNav.classList.remove('hovering')
  }


  const { primary } = slice
  const items = primary.menu?.document.data.items

  return (
    <Section id={`page-nav-${index}`} className={`[ BlockPageNav ] relative ${ index === 0 ? "pt-6 md:pt-10": ""} ${ primary.style != 'Default' ? "page-nav border-t border-clearblack pt-10 h-24" : "md:mb-16" }`}>
      {/* Default style page nav */}
      { primary.style === 'Default' &&
        <div className="container relative">
          <ul id="pageNavList" className="overflow-x-auto overflow-y-hidden flex items-center relative">
          {items.map((item, idx) => {
            let link = item.item_link.url === "/" ? item.item_link.document?.data.items[0].item_link.url : item.item_link.url
            if( item.item_link.url === "/" ) {
              if( item.item_link.document?.data.items[0].anchor_link ) {

                link = item.item_link.document?.data.items[0].anchor_link
              } else {
                link = item.item_link.document?.data.items[0].item_link.url
              }
            } else {
              if(  item.anchor_link ) {
                link = item.anchor_link
              }
            }
            return(
              <li className={`pr-4 lg:pr-10 ${ slugify(item.item_label) === page ? "active" : ""}`} key={`item-${idx}`}>
                  <Link className={`btn--line whitespace-nowrap inline-block relative overflow-hidden text-md font-medium md:text-lg lg:text-xl ${ slugify(item.item_label) === page ? "" : "opacity-50"}`} to={link}>{item.item_label}</Link>
              </li>
            )
          })}
          </ul>
        </div>
      }

      {/* Pill style page nav */}
      { primary.style != 'Default' &&
        <div className="page-nav-wrapper absolute left-0 top-10 w-full">
          <div className="container relative">
            <div id="pnProductNav" className="flex items-center justify-between relative lg:overflow-x-auto lg:overflow-y-hidden lg:whitespace-nowrap lg:px-16">
              {primary.mobile_select_label &&
                <p className="text-sm lg:hidden">{primary.mobile_select_label}</p>
              }
              <button className="flex items-center relative overflow-hidden text-sm font-medium py-2 px-6 border border-brown rounded-full text-white bg-brown lg:hidden" aria-controls="pnProductNavContents" onClick={toggleMobileDropdown}>
                <span className="block mr-2 pointer-events-none">{titlefy(title)}</span>
                <svg className="pointer-events-none" xmlns="http://www.w3.org/2000/svg" width="11" height="7" fill="none"><path stroke="#F3F2ED" strokeWidth="2" d="m9.5 1-4 4-4-4"/></svg>
              </button>
              <ul id="pnProductNavContents" className="page-nav-drop hidden absolute top-12 right-4 bg--main z-10 p-4 drop-shadow-md w-60 lg:w-auto lg:bg-transparent lg:drop-shadow-none lg:relative lg:flex lg:items-center lg:p-0 lg:top-0 lg:transition-all">
                {items.map((item, index) => {
                  let link = item.item_link.url === "/" ? item.item_link.document?.data.items[0].item_link.url : item.item_link.url
                  if( item.item_link.url === "/" ) {
                    if( item.item_link.document?.data.items[0].anchor_link ) {
                      link = item.item_link.document?.data.items[0].anchor_link
                    } else {
                      link = item.item_link.document?.data.items[0].item_link.url
                    }
                  } else {
                    if(  item.anchor_link ) {
                      link = item.anchor_link
                    }
                  }
                  return(
                    <li className={`pr-4 my-0.5 lg:inline-block ${items.length === index + 1 ? "mr-12" : ""}`} key={`item-${index}`}>
                        <Link className={`block whitespace-nowrap lg:inline-block relative lg:overflow-hidden text-md font-medium py-1 lg:py-0 lg:px-5 lg:border lg:border-clearblack lg:rounded-full text-brown lg:hover:bg-brown lg:hover:text-white lg:h-9 lg:text-sm lg:leading-9 transition-all ${ slugify(item.item_label) + `-` + page === slugify(title) ? "active lg:bg-brown lg:text-white" : "opacity-50 hover:opacity-1"}`} to={link}>{item.item_label}</Link>
                    </li>
                  )
                })}
              </ul>
            </div>
            <button id="pnAdvancerLeft" className="hidden opacity-0 lg:block absolute top-0 left-4 z-10 rotate-180">
              <span className="sr-only">Previous items</span>
              <img className="relative z-10" src={arrowDark} alt="" loading="lazy" />
            </button>
            <button id="pnAdvancerRight" className="hidden opacity-0 lg:block absolute top-0 right-4 z-10">
              <span className="sr-only">Next items</span>
              <img className="relative z-10" src={arrowDark} alt="" loading="lazy" />
            </button>
          </div>
        </div>
      }

    </Section>
  )
}
