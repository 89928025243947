import { PrismicRichText } from "@prismicio/react";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import { gsap } from "gsap";
import Cookies from "js-cookie";
import React, { Component } from "react";
import styled from "styled-components";
import validator from "validator/es";
import { media } from "../styles/media";

import hero from "../images/home--hero.jpg";
import bg from "../images/svg--contact-bg.svg";

const Section = styled.section`
  .bg--hero {
    background-repeat: no-repeat;
    background-position: right bottom;
    background-image: url(${hero});
    background-size: cover;
    border-radius: 1000px 1000px 0 0;
  }
  .form-2 {
    position: relative;
    img {
      width: 100%;
      max-width: initial;
    }
  }
  .form-2-container {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
  }
  .form-2-content {
    padding: 0 30px;
    @media ${media.mdUp} {
      padding: 0 60px;
    }
    p {
      font-size: 18px;
      line-height: 26px;
    }
  }
  .form-1,
  .form-2 {
    display: none;
    &.is-active {
      display: block;
    }
  }
`;

class BlockHeroContact extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    loading: false,
    success: false,
    error: false,
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    company: "",
    region: "",
    message: "",
    inbound: "",
  };

  componentDidMount() {
    const fadeElements = gsap.timeline();
    fadeElements.to("#block-contact", {
      opacity: 1,
      delay: 0.25,
    });
    fadeElements.from("#block-contact .fade-up", {
      duration: 0.7,
      opacity: "0",
      y: "20%",
      stagger: {
        amount: "0.5",
      },
    });
  }

  required = ($type, $name) => {
    const holder = document.querySelector(`#${$type}`);
    holder.classList.add("error");
  };

  submitForm = (e) => {
    if (e) e.preventDefault();

    if (!validator.isLength(this.state.firstName, { min: 1, max: undefined })) {
      this.required("first-name", "First name");

      return false;
    }

    if (!validator.isLength(this.state.lastName, { min: 1, max: undefined })) {
      this.required("last-name", "Last name");

      return false;
    }

    if (!validator.isEmail(this.state.email)) {
      this.required("email", "Email");

      return false;
    }

    if (!validator.isLength(this.state.company, { min: 1, max: undefined })) {
      this.required("company", "Company");

      return false;
    }

    if (!validator.isLength(this.state.region, { min: 1, max: undefined })) {
      this.required("region", "Region");

      return false;
    }

    // In Gatsby, we're building our static site in a Node
    // environment, rather than a browser environment, so anything
    // browser related needs to be wrapped in some version of the
    // following isBrowser variable.

    // What we're getting here is some contextual data to send
    // along to Hubspot so it can organize and track forms as
    // they relate to specific users in the CRM.  The Hubspot
    // plugin we installed earlier provides this hutk value as a cookie.
    // pageName & pageUri should be pretty self explanatory.
    const portalId = 5880939;
    const formId = "8f3e1f6f-07b7-4a51-a54c-bedeb27ed18f";
    const isBrowser = typeof window !== "undefined";
    const hutk = isBrowser ? Cookies.get("hubspotutk") : null;
    const pageUri = isBrowser ? window.location.href : null;
    const pageName = isBrowser ? document.title : null;
    const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

    this.setState({ loading: true });

    // This object shape is what's required in the Hubspot API
    // documentation.  There's an additional legalConsentOptions object
    // I've left out here, but would be important if you're working on a
    // legitimate organization's site & they want to be safe from any sort
    // of GDPR guff.
    //
    // Read more here:
    // https://developers.hubspot.com/docs/methods/forms/submit_form_v3

    const urlString = window.location.href;
    const url = new URL(urlString);

    const body = {
      submittedAt: Date.now(),
      fields: [
        {
          name: "firstname",
          value: this.state.firstName.replace("&nbsp;", ""),
        },
        {
          name: "lastname",
          value: this.state.lastName.replace("&nbsp;", ""),
        },
        {
          name: "email",
          value: this.state.email,
        },
        {
          name: "mobilephone",
          value: this.state.phone,
        },
        {
          name: "company",
          value: this.state.company.replace("&nbsp;", ""),
        },
        {
          name: "country",
          value: this.state.region,
        },
        {
          name: "message",
          value: this.state.message.replace("&nbsp;", ""),
        },
        {
          name: "inbound_source",
          value: this.state.inbound.replace("&nbsp;", ""),
        },
      ],
      context: {
        hutk,
        pageUri,
        pageName,
      },
    };

    // These specific headers are required for whatever reason,
    // so don't forget to include them.
    fetch(postUrl, {
      method: "post",
      body: JSON.stringify(body),
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json, application/xml, text/plain, text/html, *.*",
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        const message = document.querySelector(".form-2-content");
        message.innerHTML = res.inlineMessage;
        const form1 = document.querySelector("#block-contact .form-1");
        const form2 = document.querySelector("#block-contact .form-2");
        form1.classList.remove("is-active");
        form2.classList.add("is-active");
        this.setState({ success: true });
        this.setState({ error: false });
        this.setState({ loading: false });

        if (this.props.slice.primary.submit_redirect_url.text) {
          window
            .open(this.props.slice.primary.submit_redirect_url.text, "_blank")
            .focus();
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ success: false });
        this.setState({ error: err });
        this.setState({ loading: false });
      });
  };

  render() {
    const { primary } = this.props.slice;
    const imgData = getImage(primary.image);

    return (
      <Section id="block-contact" className="container relative">
        <div className="grid grid-cols-12 pt-10 md:pt-16">
          <div className="col-span-12 md:col-span-5">
            <h1 className="h2 mb-6 color--primary fade-up">
              {primary.heading}
            </h1>
            <p className="text-lg">{primary.content.text}</p>
            <div className="grid grid-cols-5">
              <div className="col-span-5 xl:col-span-4 mb-16">
                <div className="nav--contact flex justify-between mt-12 content--story">
                  <div>
                    <p className="eyebrow mb-3">Email</p>
                    {primary.email && primary.email.length > 6 ? (
                      <p className="small mb-0">
                        <a
                          href={`mailto:${primary.email}`}
                          className="menu--link color--grey-5"
                        >
                          {primary.email}
                        </a>
                      </p>
                    ) : (
                      <></>
                    )}
                    {primary.content_small ? (
                      <PrismicRichText field={primary.content_small.richText} />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-4">
                <div className="form-1 is-active">
                  <div className="relative input-group mb-4">
                    <input
                      type="text"
                      placeholder="First Name*"
                      id="first-name"
                      onKeyUp={(e) => {
                        this.setState({ firstName: e.target.value });
                      }}
                    />
                    <label>First Name*</label>
                  </div>
                  <div className="relative input-group mb-4">
                    <input
                      type="text"
                      placeholder="Last Name*"
                      id="last-name"
                      onKeyUp={(e) => {
                        this.setState({ lastName: e.target.value });
                      }}
                    />
                    <label>Last Name*</label>
                  </div>
                  <div className="relative input-group mb-4">
                    <input
                      type="email"
                      placeholder="Work Email*"
                      id="email"
                      onKeyUp={(e) => {
                        this.setState({ email: e.target.value });
                      }}
                    />
                    <label>Work Email*</label>
                  </div>
                  <div className="relative input-group mb-4">
                    <input
                      type="tel"
                      placeholder="Phone Number"
                      id="phone"
                      onKeyUp={(e) => {
                        this.setState({ phone: e.target.value });
                      }}
                    />
                    <label>Phone Number</label>
                  </div>
                  <div className="relative input-group mb-4">
                    <input
                      type="text"
                      placeholder="Company*"
                      id="company"
                      onKeyUp={(e) => {
                        this.setState({ company: e.target.value });
                      }}
                    />
                    <label>Company*</label>
                  </div>
                  <div className="relative input-group mb-4">
                    <input
                      type="text"
                      placeholder="Location/Country*"
                      id="region"
                      onKeyUp={(e) => {
                        this.setState({ region: e.target.value });
                      }}
                    />
                    <label>Location/Country*</label>
                  </div>
                  <div className="relative input-group mb-4">
                    <textarea
                      id="message"
                      onKeyUp={(e) => {
                        this.setState({ message: e.target.value });
                      }}
                      placeholder="Tell us why you are reaching out."
                    />
                  </div>
                  <div className="relative input-group mb-4">
                    <select
                      id="inbound"
                      defaultValue={""}
                      onChange={(e) => {
                        this.setState({ inbound: e.target.value });
                      }}
                    >
                      <option
                        label="How'd you hear about us?"
                        disabled
                        value=""
                      >
                        How'd you hear about us?
                      </option>
                      <option label="Search engine" value="Search engine">
                        Search engine
                      </option>
                      <option label="Social media" value="Social media">
                        Social media
                      </option>
                      <option label="TRC newsletter" value="TRC newsletter">
                        TRC newsletter
                      </option>
                      <option
                        label="Media / online news article"
                        value="Media / online news article"
                      >
                        Media / online news article
                      </option>
                      <option
                        label="Referral / word of mouth"
                        value="Referral / word of mouth"
                      >
                        Referral / word of mouth
                      </option>
                      <option
                        label="Event / conference"
                        value="Event / conference"
                      >
                        Event / conference
                      </option>
                      <option label="Other" value="Other">
                        Other
                      </option>
                    </select>
                    {/* <label>Location/Country*</label> */}
                  </div>
                  <button
                    type="button"
                    onClick={this.submitForm}
                    className="btn--rounded bg--brown"
                  >
                    Send
                  </button>
                </div>
                <div className="form-2">
                  <div className="form-2-container">
                    <div className="form-2-content">
                      <p>Thank you for your interest in TRC.</p>
                      <p>A member of our team will be in touch shortly.</p>
                    </div>
                  </div>

                  <img src={bg} alt="" loading="lazy" />
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-6 col-start-7 hidden md:block">
            {imgData ? (
              <GatsbyImage
                image={imgData}
                alt=""
                loading="eager"
                className="radius-56"
              />
            ) : (
              <StaticImage
                src="../images/contact--hero.jpg"
                loading="eager"
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt=""
                className="radius-56"
              />
            )}
          </div>
        </div>
      </Section>
    );
  }
}

export default BlockHeroContact;
