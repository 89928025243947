import React, { Component } from 'react'
import { gsap } from 'gsap'
import logo from '../images/the-routing-company.svg'


class BlockHeroSimple extends Component {

  componentDidMount() {
    const fadeElements = gsap.timeline()
    fadeElements.to('#block-hero-simple', {
      opacity: 1,
      delay: 0.25,
    })
    fadeElements.from('#block-hero-simple .fade-up', {
      duration: 0.7,
      opacity: '0',
      y: '20%',
      stagger: {
        amount: '0.5',
      },
    })
  }
  

  render() {
    const { primary } = this.props.slice

    return (
      <header id="block-hero-simple" className="container relative">
        <div className="grid grid-cols-12 pt-10 md:pt-16">
          <div className="col-span-12 md:col-span-6">
            <h1 className="h1 md:mb-10 color--primary fade-up">
              {primary.heading}
            </h1>
          </div>
          <div className="col-span-6 hidden md:flex justify-end items-start">
            <img
              src={logo}
              alt="The Routing Company"
              className="fade-up w-10 logo"
            />
          </div>
        </div>
        <div className="grid grid-cols-12 md:mt-10 mb-10 md:mb-24">
          <div className="col-span-12 md:col-span-2">
            <span className="eyebrow-name fade-up">{primary.eyebrow}</span>
          </div>
          <div className="col-span-12 md:col-span-7 md:col-start-6">
            <p className="text-lg md:text-3xl fade-up">{primary.content.text}</p>
          </div>
        </div>
      </header>
    )
  }
}
export default BlockHeroSimple;
