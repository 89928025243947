import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { gsap } from 'gsap'
// import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";

const Section = styled.header`
  p,
  li {
    margin: 1.5rem 0;
  }
  ol,
  ul {
    margin: 1.5rem;
  }
  ol {
    list-style: decimal;
    ol {
      list-style: upper-alpha;
    }
    ul ul {
      list-style: circle;
    }
  }
  ul {
    list-style-type: disc;
  }
  table {
    td {
      padding: 15px;
      vertical-align: top;
      border: 1px solid #292524;
    }
    p {
      margin-top: 0;
      margin-bottom: 10px;
    }
    ul {
      margin-top: 0;
    }
    li {
      margin: 0;
    }
  }
`
function langResolver(altLang, currLang, slug) {
  // Warning!!!! This assumes that the slug for en-us and nl-nl follow this format:
  // en-us: "/this-is-my-article"
  // nl-nl: "nl-nl/this-is-my-article"
  // The user has to copy the slug and prepend it with a language code manually.
  // However the user has total control over the slug so switching languages may not work since
  // Prismic documents were never setup with a uid. For example the user could enter:
  // - en-us: "/this-is-my-article"
  // - nl-nl: "i dont know what I'm doing" or "asddsa/adsdsaasddas/adsdassad"
  // The regex replaces double slashes just in case...
  if (altLang == 'en-us') {
    return ("/" + slug.replace(currLang + "/", "")).replace(/\/\//g, "/")
  } else if (currLang == 'en-us') {
    // go to the page without a language "path"
    return ("/" + altLang + "/" + slug).replace(/\/\//g, "/")
  } else {
    // all non-en pages begin with the 5-letter country/language code from (Prismic)
    return ("/" + slug.replace(currLang + "/", altLang + "/")).replace(/\/\//g, "/")
  }
}

class BlockPrivacy extends Component {
  componentDidMount() {
    // if (getCookieConsentValue === 'true'){
      // redirect:
      const langTable = [
        {
          browserLang: 'en',
          prismicLang: 'en-us',
          path: '',
        },
        {
          browserLang: 'en-US',
          prismicLang: 'en-us',
          path: '',
        },
        {
          browserLang: 'nl',
          prismicLang: 'nl-nl',
          path: 'nl-nl/',
        },
      ]
      const nl = navigator.languages.indexOf('nl')
      const en = Math.min(navigator.languages.indexOf('en'), navigator.languages.indexOf('en-US'))
      let prefLang = 'en'
        if (localStorage.getItem('prefLang')) {
          prefLang = localStorage.getItem('prefLang')
        } else {
          if (nl >= 0 && nl < en) {
            prefLang = 'nl'
          }
          localStorage.setItem('prefLang', prefLang)
        }

      // console.log(nl, en)
      // console.log(navigator.languages)
      // console.log(this.props.altLangs.map(e=>e.lang))
      // console.log('prefLang: '+prefLang)
      // console.log('storedLang: '+localStorage.getItem('prefLang'))
      if (prefLang === 'nl' && window.location.pathname !== `/nl-nl/${this.props.page}` ) {
        window.location.href = `//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/nl-nl/${this.props.page}`
      } else if (prefLang === 'en' && window.location.pathname !== `/${this.props.page}` ) {
        window.location.href = `//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/${this.props.page}`
      }
    // }
    // gsap:
    const fadeElements = gsap.timeline()
    fadeElements.to('#block-privacy', {
      opacity: 1,
      delay: 0.25,
    })
    fadeElements.from('#block-privacy .fade-up', {
      duration: 0.7,
      opacity: '0',
      y: '20%',
      stagger: {
        amount: '0.5',
      },
    })
  }

  render() {
    const { primary, items } = this.props.slice
    console.log(this.props)
    return (
      <Section id="block-hero" className="container relative">
        <div className="relative pt-10 md:pt-20 mb-24">
          <div className="grid grid-cols-12 relative z-10">
            <div className="col-span-12 md:col-span-5">
              <h1 id="block-privacy" className="h1 mb-10 color--primary fade-up">
                {this.props.title}
              </h1>
              { this.props.altLangs.length > 0 &&
                <div className="[altLangs] html-content">
                  <p className="flex gap-3 uppercase">
                    <strong>{ this.props.lang }</strong>
                      {this.props.altLangs.map((item, itemIdx) => (
                        <Link key={`alt-lang-${itemIdx}`} to={langResolver(item.lang, this.props.lang, this.props.slug)} onClick={()=>{
                          // if (getCookieConsentValue === 'true'){
                            localStorage.setItem('prefLang', item.lang.split('-')[0])
                          // }
                        }}>{item.lang}</Link>
                      ))}
                  </p>
                  {/* <pre>{ JSON.stringify(this.props.altLangs) }</pre> */}
                </div>
              }
            </div>
            <div className="col-span-12 md:col-span-6 md:col-start-7">
              {(items.map((item, itemIdx) => (
                  <div
                    key={`item${itemIdx}`}
                    className="text-md html-content"
                    dangerouslySetInnerHTML={{ __html: item.content.html }}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </Section>
    )
  }
}
export default BlockPrivacy
