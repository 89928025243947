import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { media } from '../styles/media'
import Marquee from 'react-fast-marquee'
//import Marquee from "react-easy-marquee";
// import bg from '../images/home-partners.jpg'

const Section = styled.section`
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  // @media ${media.lgUp} {
  //   background-image: url(${bg});
  // }
  .bg--half {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    @media ${media.lgUp} {
      width: 45%;
    }
  }
  .bg-light-brown {
    position: relative;
    background-color: #312d2c;
    z-index: 10;
  }
  .marquee {
    overflow: hidden;
    img {
      transform: scale(0.5);
      @media ${media.lgUp} {
        margin: 0;
        transform: scale(0.5);
      }
    }
  }
`

class BlockHomePartners extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { primary, items } = this.props.slice
    const bgStyle = {
      backgroundImage: `url(${primary.image.url || ''})`,
    }
    return (
      <Section style={bgStyle} className="[ BlockPartners ] relative mt-16 md:mt-24">
        <div className="container relative z-10">
          <div className="grid grid-cols-12">
            <div className="col-span-6 pt-20 pb-20 items-center hidden lg:flex">
              <h2 className="h1 text-white">{primary.heading}</h2>
            </div>
            <div className="col-span-12 md:col-span-6 pt-16 pb-16 lg:pl-32 md:pt-20 md:pb-20 items-center flex">
              <div>
                <div className="hidden lg:block">
                  <span className="eyebrow-num white w-2/5 mb-10">
                    {primary.eyebrow}
                  </span>
                </div>
                <div className="lg:hidden">
                  <h2 className="h1 text-white mb-4">{primary.eyebrow}</h2>
                  <p className="text-white mb-8">{primary.heading}</p>
                </div>
                <div
                  className="text-lg md:text-3xl text-white mb-10 md:mb-16"
                  dangerouslySetInnerHTML={{ __html: primary.content.html }}
                />
                <div className="flex items-center">
                  <Link
                    to={primary.button_link_1}
                    className="btn--rounded bg--tan"
                  >
                    {primary.button_text_1}
                  </Link>
                  <Link
                    to={primary.button_link_2}
                    className="text-white hover:opacity-80 ml-8 block text-sm font-medium"
                  >
                    {primary.button_text_2}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg--half bg--brown" />
        <div className="bg-light-brown overflow-hidden">
          <div className="marquee">
            <div className="marquee--inner">
              <Marquee gradient={false}>
                {items.map((item, index) => (
                  <React.Fragment key={`marquee${index}`}>
                    { item.logo.url != null &&
                      <img src={item.logo.url} alt={item.logo.alt ? item.logo.alt : ""} width={item.logo.dimensions.width} height={item.logo.dimensions.height} />
                    }
                  </React.Fragment>
                ))}
              </Marquee>
            </div>
          </div>
        </div>
      </Section>
    )
  }
}
export default BlockHomePartners
