import React, { Component, useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { media } from '../styles/media'
import arrow from '../images/circle-arrow-dark.svg'

const Nav = styled.nav`
  .nav--large {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin-bottom: 10px;
      @media ${media.mdUp} {
        margin-bottom: 20px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    > li {
      > a {
        &::before {
          position: absolute;
          bottom: 0;
          left: 0;
          content: '';
          width: 100%;
          height: 2px;
          background: var(--color-primary);
          transition: all 0.15s linear;
          transform: translateX(calc(-100% - 0.1rem));
          animation: 0.25s linear 0s line-out forwards;
        }
        &:hover,
        &:focus {
          color: var(--color-primary);
          &::before {
            animation: 0.25s linear 0s line-in forwards;
          }
        }
      }
    }
    .sub-secondary > li {
      > a {
        &::before {
          display: none;
        }
      }
    }
    a, button {
      display: inline-block;
      padding: 0 0 10px 0;
      position: relative;
      font-weight: 500;
      font-size: 36px;
      line-height: 43px;
      letter-spacing: -0.02em;
      overflow: hidden;
      @media ${media.mdUp} {
        font-size: 56px;
        line-height: 48px;
      }
    }
    .sub-secondary {
      a {
        display: block;
        font-size: 18px;
      }
    }
  }
  .sub-primary {
    &[aria-hidden="true"] {
      transform: translateX(100%);
    }
  }
  .sub-primary,
  .sub-secondary {
    &[aria-hidden="true"] {
      visibility: hidden;
      max-height: 0;
      overflow: hidden;
    }
  }

  .sub-primary {
    top: 70px;
    z-index: 1;
    height: calc(100vh - 70px);

    &[aria-hidden="false"] {
      transition: .3s ease .2s;
    }
    &[aria-hidden="true"] {
      transition: .3s ease;
    }

    > li {
      max-width: 375px;
      margin-left: auto;
      margin-right: auto;

      @media ${media.mdUp} {
        max-width: 720px;
      }

      > a {
        display: inline-block;
        padding: 0 0 10px 0;
        position: relative;
        font-weight: 500;
        font-size: 36px;
        line-height: 43px;
        letter-spacing: -0.02em;
        overflow: hidden;
        @media ${media.mdUp} {
          font-size: 56px;
          line-height: 48px;
        }
      }
    }

    .eyebrow-name {
      margin-top: 32px;
    }
  }

  .sub-secondary {
    &[aria-hidden="false"] {
      margin-bottom: 2rem;
      transition: .2s ease;
    }
    &[aria-hidden="true"] {
      transition: 0s;
    }
  }

  .sub-expand {
    &:before {
      background: var(--color-brown);
      content: '';
      position: absolute;
      right: 8px;
      top: 12px;
      width: 2px;
      height: 18px;
    }
    &:after {
      background: var(--color-brown);
      content: '';
      position: absolute;
      right: 0px;
      top: 20px;
      width: 18px;
      height: 2px;
    }
    &[aria-expanded="true"] {
      &:before {
        opacity: 0;
      }
    }
  }
`

export default function NavLarge({ nav, closeModal }) {

  const [activeAccordion, setActiveAccordion] = useState(null)

  const toggleSubMenu = (e) => {
    e.preventDefault()
    const target = e.target.getAttribute('aria-controls')
    const panel = document.getElementById(target)
    const attr = panel.attributes
    const navDrop = document.getElementById('nav-drop')

    if (attr['aria-hidden'].value == "true") {
      panel.setAttribute("aria-hidden", "false")
      panel.setAttribute("aria-expanded", "true")
      navDrop.classList.add('is-sub')
    } else {
      panel.setAttribute("aria-hidden", "true")
      panel.setAttribute("aria-expanded", "false")
      navDrop.classList.remove('is-sub')
    }
  }

  const toggleAccordion = (index) => {
    activeAccordion === index ? setActiveAccordion(null) : setActiveAccordion(index)
  }

  const openSecondary = (e) => {
    e.preventDefault();
    console.log(`clicky`)
  }

  return (
    <Nav className="order-1 lg:order-2 col-span-5 lg:pt-32">
      <ul className="nav--large">
        {nav.map((item, index) => (
          <li key={index}>
            { item.menu.id != null &&
              <React.Fragment>
                <Link className="lg:!hidden" to={item.menu.document.data.items[index].item_link.url === '/' ? item.menu.document.data.items[index].item_link.document.data.items[0].item_link.url : item.menu.document.data.items[index].item_link.url} aria-haspopup="true" aria-controls={`nl-panel-${index}`} onClick={toggleSubMenu}>
                  <span className="inline-block pointer-events-none">{item.title}</span>
                  <img className="inline-block pointer-events-none ml-2" src={arrow} alt="" />
                </Link>
                <Link to={item.menu.document.data.items[index].item_link.url === '/' ? item.menu.document.data.items[index].item_link.document.data.items[0].item_link.url : item.menu.document.data.items[index].item_link.url} className="!hidden lg:!inline-block" onClick={closeModal}>
                  {item.title}
                </Link>
              </React.Fragment>
            }
            { item.menu.id === null &&
              <Link to={item.url} onClick={closeModal}>
                {item.title}
              </Link>
            }
          </li>
        ))}
      </ul>
      {nav.map((item, index) => (
        <React.Fragment key={`sub-${index}`}>
          { item.menu.id != null &&
            <ul id={`nl-panel-${index}`} className="sub-primary fixed left-0 w-screen bg--main px-4 lg:!hidden overflow-x-hidden overflow-y-auto" key={`panel-${index}`} aria-hidden="true" aria-expanded="false">
              <li className="w-full mt-6">
                <button aria-controls={`nl-panel-${index}`} onClick={toggleSubMenu}>
                  <span className="sr-only">Go back</span>
                  <img className="pointer-events-none rotate-180" src={arrow} alt="" />
                </button>
                <br />
                <span className="eyebrow-name">
                  {item.title}
                </span>
              </li>
              {item.menu.document.data.items.map((item,index) => {
                return(
                  <li className="w-full my-1" key={`sub-${index}`}>
                    {item.item_link.document && Object.keys(item.item_link.document).length === 0 &&
                      <Link to={item.item_link.url} onClick={closeModal}>
                        {item.item_label}
                      </Link>
                    }
                    {item.item_link.document && Object.keys(item.item_link.document).length != 0 &&
                      <React.Fragment>
                        <Link to={item.item_link.document.data.items[0].anchor_link ? item.item_link.document.data.items[0].anchor_link : item.item_link.document.data.items[0].item_link.url} className="sub-expand w-full" aria-haspopup="true" aria-controls={`sub-menu-${index}`} aria-expanded={ activeAccordion === index ? "true" : "false"} onClick={() => toggleAccordion(index)}>
                          {item.item_label}
                        </Link>
                        <ul id={`sub-menu-${index}`} className="sub-secondary" aria-hidden={ activeAccordion === index ? "false" : "true"} aria-label={`${item.item_label} submenu`}>
                          {item.item_link.document.data.items.map((item,index) =>
                            <li key={`sub-sub-item-${index}`}>
                              <Link to={item.anchor_link ? item.anchor_link : item.item_link.url} className="!flex items-center justify-between lh-solid py-1.5 border-b border-gray" onClick={closeModal}>
                                <span>{item.item_label}</span>
                                <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.5 19.3828L21.9094 19.3828L16.6687 24.6234L18 25.9453L25.5 18.4453L18 10.9453L16.6781 12.2672L21.9094 17.5078L10.5 17.5078L10.5 19.3828Z" fill="#373736"/></svg>
                              </Link>
                            </li>
                          )}
                        </ul>
                      </React.Fragment>
                    }
                  </li>
                )
              })}
            </ul>
          }
        </React.Fragment>
      ))}
    </Nav>
  )
}
