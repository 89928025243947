import React, { Component } from 'react'
import { PrismicRichText } from '@prismicio/react'
import styled from 'styled-components'

const Embed = styled.div`
  position: relative;
  min-height: 3200px;

  @media (min-width: 720px) {
    min-height: 3100px;
  }
  @media (min-width: 1025px) {
    min-height: 2900px;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`

class BlockEmbed extends Component {

  render() {
    const { primary, items } = this.props.slice

    // console.log(`block list`, primary)

    return (
      <section
        className={`[ BlockEmbed ] mt-16 md:mt-24 ${primary.background === 'Dark' ? "bg--primary text-white py-16 md:py-24" : ""}`}
      >
        <div className="container relative">
          <div className="grid grid-cols-12 gap-x-6 md:mb-10">
            <div className="fade-up col-span-12 mb-10 md:col-span-7">
              <h2 className="h1 fade-up">
                <span className="block" dangerouslySetInnerHTML={{ __html: primary.heading }} />
              </h2>
            </div>
          </div>

          
          <div className="grid grid-cols-12 gap-x-6">
            {primary.content &&
              <div className="html-content col-span-12 md:col-span-5">                
                <PrismicRichText
                  field={primary.content.richText}
                  components={{
                    paragraph: ({ children }) => <p className='text-lg md:text-xl fade-up'>{children}</p>,
                  }}
                />
              </div>
            }
            {primary.embed_code &&
              <Embed
                className="content col-span-12 md:col-span-7 md:col-start-6 mt-6 md:mt-0"
                dangerouslySetInnerHTML={{ __html: primary.embed_code }}
              />
            }            
          </div>
        </div>
      </section>
    )
  }
}
export default BlockEmbed
