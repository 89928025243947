import React, { Component } from 'react'
import { PrismicRichText, PrismicLink } from '@prismicio/react'


class BlockContentColumns extends Component {

  render() {
    const { primary, items } = this.props.slice

    return (
      <section className="[ BlockContentColumn ] mt-16 md:mt-24">
        <div className="container relative">
          <div className="grid grid-cols-12 md:gap-6 lg:gap-20">
            { primary.column_1 && primary.column_1.richText.length > 0 &&
              <div className="content--story col-span-12 md:col-span-6">
                <PrismicRichText field={primary.column_1.richText} />
              </div>
            }
            { primary.column_2 && primary.column_2.richText.length > 0 &&
              <div className="content--story col-span-12 md:col-span-6">
                <PrismicRichText field={primary.column_2.richText} />
                <div className="flex items-center">
                  { primary.button_1_link && primary.button_1_label &&
                    <PrismicLink className="btn--rounded mt-4" field={primary.button_1_link}>{primary.button_1_label}</PrismicLink>
                  }
                  { primary.button_2_link && primary.button_2_label &&
                    <PrismicLink className="btn--rounded bg--brown mt-4 ml-4" field={primary.button_2_link}>{primary.button_2_label}</PrismicLink>
                  }
                </div>
              </div>
            }
          </div>
        </div>
      </section>
    )
  }
}
export default BlockContentColumns
