import React, { Component } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

class BlockImage extends Component {

  render() {
    const { primary, items } = this.props.slice

    return (
      <section className="[ BlockImage ] mt-16 md:mt-24">
        <div className="container relative">
          <GatsbyImage
            image={primary.image1.gatsbyImageData}
            alt={primary.image1.alt||''}
            className="radius-32"
          />
        </div>
      </section>
    )
  }
}
export default BlockImage
