import React, { Component } from 'react'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import google from '../images/svg--google.svg'
import apple from '../images/svg--apple.svg'

class BlockApps extends Component {
  render() {
    const { primary, items } = this.props.slice
    const { global } = this.props

    return (
      <section className="[ BlockApps ] container relative pt-16 md:pt-24">
        <div className="grid grid-cols-12 md:mb-10">
          <div className="col-span-12 md:col-span-6">
            <h2 className="h1">{primary.heading}</h2>
          </div>
        </div>
        <div className="grid grid-cols-12 mb-16 md:mb-24 items-center">
          <div className="col-span-12 md:col-span-6 order-2 md:order-1">
            <div className="grid grid-cols-6">
              <div className="col-span-4 md:col-span-2 mt-8 md:mt-0 md:mb-10">
                <span className="eyebrow-name">{primary.eyebrow_1}</span>
              </div>
              <div className="col-span-12 md:col-span-5">
                <h3 className="h2 mb-6 opacity-50">{primary.title_1}</h3>
              </div>
              <div className="col-span-12 md:col-span-4">
                <p className="text-lg mb-6">{primary.content_1.text}</p>
                <ul className="dash-list">
                  {items.map((item, index) => (
                    <li key={`content1${index}`}>{item.list_1}</li>
                  ))}
                </ul>
                <div className="flex mt-16">
                  <a href={ !global ? '' : global.google_link } target="_blank" rel="noreferrer">
                    <img src={google} alt="Get it on Google Play" />
                  </a>
                  <a
                    href={ !global ? '' : global.apple_link }
                    target="_blank"
                    className="ml-2"
                    rel="noreferrer"
                  >
                    <img src={apple} alt="Download on the App Store" loading="lazy" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-6 col-start-3 md:col-span-3 md:col-start-8 mt-16 md:mt-0 order-1 md:order-2">
            <GatsbyImage
              image={primary.image_1.gatsbyImageData}
              alt={primary.image_1.alt||''}
              className="drop-shadow"
            />
          </div>
        </div>
        <div className="grid grid-cols-12 mb-16 md:mb-24 items-center">
          <div className="col-span-6 col-start-3 md:col-span-3 md:col-start-2">
            <GatsbyImage
              image={primary.image_2.gatsbyImageData}
              alt={primary.image_2.alt||''}
              className="drop-shadow"
            />
          </div>
          <div className="col-span-12 md:col-span-6 md:col-start-7">
            <div className="grid grid-cols-6">
              <div className="col-span-12 md:col-span-2 mt-8 md:mt-0 md:mb-10">
                <span className="eyebrow-name">{primary.eyebrow_2}</span>
              </div>
              <div className="col-span-12 md:col-span-5">
                <h3 className="h2 mb-6 opacity-50">{primary.title_2}</h3>
              </div>
              <div className="col-span-12 md:col-span-4">
                <p className="text-lg mb-6">{primary.content_2.text}</p>
                <ul className="dash-list">
                  {items.map((item, index) => (
                    <li key={`content2${index}`}>{item.list_2}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
export default BlockApps
