import { Link } from "gatsby"
// import { GatsbyImage } from 'gatsby-plugin-image'
import { default as React } from "react"
import {
  connectStateResults,
  Highlight,
  Hits,
  Index,
  Snippet,
} from "react-instantsearch-dom"
// import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import arrow from '../../images/circle-arrow-dark.svg'
import logo from '../../images/the-routing-company-mark.svg'

const HitCount = connectStateResults(
  ({ searchResults }) => {
    console.log('HitCount running...')
    const hitCount = searchResults && searchResults.nbHits

    return (hitCount > 0) ? (
      <h2 className="HitCount text-3xl font-bold">
        {hitCount} result{hitCount !== 1 ? `s` : ``}
        {/* <pre>{ JSON.stringify(title) }</pre> */}
      </h2>
    ) : null
  }
)

{/* this will be wrapped in li.Hits-item by instantsearch-dom */}
const PageHit = ({ hit }) => (
    <Link
      to={('/'+hit.slug).split('//').join('/')}
      className="block mb-20 w-full"
    >
      {hit.image && hit.image.url
        ? <img
            src={hit.image.url}
            alt={hit.image.alt}
            className="radius-32 block w-full aspect-newsroom object-cover"
            width="540"
            height="450"
            loading="lazy"
          />
        : <div class="radius-32 w-full aspect-newsroom bg-gray flex items-center justify-center">
            <img
              src={logo} alt=""
              className="mx-auto -mt-20 opacity-20 w-2/4 md:w-2/6"
              width="180"
            />
          </div>
      }
      <div className="radius-32 p-5 -mt-20 bg--main relative z-10">
        <div className="flex relative justify-between items-start">
          <div>
            <span className="mb-4 pill pill--gray uppercase">
              {hit.tag || 'Page'}
            </span>
            <span className="text-xs ml-4">
              {hit.date}
            </span>
          </div>
          <img src={arrow} alt="" />
        </div>
        <h3 className="text-lg font-medium mb-4">
          {typeof hit.title === 'string'
            ? hit.title || 'No Title?'
            : hit.title.text || 'No Title?'
          }
          {
            hit.slug.includes('nl-nl/')
            ? <span> (nl-nl)</span>
            : <span></span>
          }
        </h3>
        {/* <Snippet attribute="text" hit={hit} tagName="mark" /> */}
        <p>{hit.description}</p>
        {/* <pre className="outline whitespace-normal">{ JSON.stringify(hit, null, 1) }</pre> */}
      </div>
    </Link>
)

const HitsInIndex = ({ index }) => (
  <Index className="HitInIndex" indexName={index.name}>
    {/* <pre className="block">{ JSON.stringify(index) }</pre>
    <div className="mt-8">
        <div className="eyebrow">{index.title}</div>
      <HitCount/>
    </div> */}
    {/* <HitCount/> */}
    <Hits
      className="Hits"
      hitComponent={PageHit}
    />
  </Index>
)

const SearchResult = ({ indices, className }) => (
  <div className={[className, 'SearchResult mb-24'].join(' ')}>
    {indices.map(index => (
      <HitsInIndex index={index} key={index.name} />
    ))}
  </div>
)

export default SearchResult