import React, { Component, useState, useLayoutEffect } from 'react'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'

export default function BlockMatrix({ slice, data }) {

  const { primary, items, index } = slice

  const [activeTab, setActiveTab] = useState(1)


  useLayoutEffect(() => {
    if(typeof window !== `undefined` ) {
      const queryString = window.location.search;
      const params = new URLSearchParams(queryString)
      const paramTab = params.get("tab");
      const initTab = paramTab ? parseInt(paramTab) : 1

      setActiveTab(initTab) // update active tab

      // scroll to tab
      if( initTab ) {
        const el = document.getElementById('block-matrix')
        const top = window.scrollY + el.getBoundingClientRect().top
        window.scrollTo({ top, behavior: "smooth" })
      }
    }
  }, [])

  const tabClick = (idx) => {
    const activeTab = parseInt(idx) + 1
    setActiveTab(activeTab);
  }

  return (
    <section
      id="block-matrix"
      className="[ BlockMatrix ] mt-16 md:mt-24"
    >
      <div className="container relative">
        <div className="grid grid-cols-12">

          <div className="col-span-12 md:col-span-4 mb-8 md:mb-0">
            {primary.heading != null &&
              <h2 className='h2'>{primary.heading}</h2>
            }
            <div className="mt-8">
              {items.map((item,idx) => (
                <div
                  id={`matrix-desc-${idx}`}
                  className={` ${idx === activeTab - 1 ? "transition duration-500 opacity-1 visibility-visible max-h-none" : "opacity-0 visibility-hidden overflow-hidden max-h-0"}`}
                  key={`desc-${idx}`}
                  aria-hidden={idx === activeTab - 1 ? "false" : "true"}
                >
                  <PrismicRichText field={item.description_text.richText} />
                </div>
              ))}
            </div>
          </div>

          <div className="col-span-12 md:col-start-6 md:col-end-12">
            <div className="bg-gray inline-flex items-center p-4 rounded-lg mb-8 float-right">
              <span className="flex items-center font-medium leading-none mr-6"><span className="inline-block w-4 h-4 bg-brown rounded-full mr-2"></span><span>Essential</span></span>
              <span className="flex items-center font-medium leading-none"><span className="inline-block w-4 h-4 bg-red rounded-full mr-2"></span><span>Optional</span></span>
            </div>
            <div className="border-b border-gray mb-10 clear-both">
              <div className="flex items-end justify-between -mx-2" role="tablist">
                {items.map((item,idx) => (
                  <button
                    key={`btn-${idx}`}
                    className={`text-sm lg:text-md mx-2 font-medium pb-2 border-b-2 transition ${idx === activeTab - 1 ? "border-brown" : "border-transparent hover:border-brown"}`}
                    role="tab"
                    id={`matrix-tab-${idx}`}
                    aria-selected={idx === activeTab - 1 ? "true" : "false"}
                    aria-controls={`matrix-panel-${idx}`}
                    onClick={() => tabClick(idx)}
                  >{item.name}</button>
                ))}
              </div>
            </div>
            {items.map((item,idx) => (
              <div
                id={`matrix-panel-${idx}`}
                className={` ${idx === activeTab - 1 ? "transition duration-500 opacity-1 visibility-visible max-h-none" : "opacity-0 visibility-hidden overflow-hidden max-h-0"}`}
                role="tabpanel"
                tabIndex="0"
                aria-labelledby={`matrix-tab-${idx}`}
                aria-hidden={idx === activeTab - 1 ? "false" : "true"}
                key={`panel-${idx}`}
              >
                <dl className="grid grid-cols-6 gap-y-6 lg:text-lg font-medium -ml-2 -mr-2">
                  <dt className={`ml-2 col-span-2 border-b border-gray pb-3 ${item.pingo_flex == 'Disable' ? "opacity-50" : ""}`}>Pingo Flex™</dt>
                  <dd className="col-span-1 mr-2 border-b border-gray pb-3 pl-3 flex items-center justify-center">
                    <span className={`${item.pingo_flex == 'Disable' ? "hidden" : "block"} w-4 h-4 rounded-full ${item.pingo_flex == 'Essential' ? "bg-brown" : "bg-red"}`}></span>
                    <span className="sr-only">{item.pingo_flex}</span>
                  </dd>
                  <dt className={`ml-2 col-span-2 border-b border-gray pb-3 ${item.transit_connect == 'Disable' ? "opacity-50" : ""}`}>Transit Connect™</dt>
                  <dd className="col-span-1 mr-2 border-b border-gray pb-3 pl-3 flex items-center justify-center">
                    <span className={`${item.transit_connect == 'Disable' ? "hidden" : "block"} w-4 h-4 rounded-full ${item.transit_connect == 'Essential' ? "bg-brown" : "bg-red"}`}></span>
                    <span className="sr-only">{item.transit_connect}</span>
                  </dd>
                  <dt className={`ml-2 col-span-2 border-b border-gray pb-3 ${item.pingo_journey == 'Disable' ? "opacity-50" : ""}`}>Pingo Journey™</dt>
                  <dd className="col-span-1 mr-2 border-b border-gray pb-3 pl-3 flex items-center justify-center">
                    <span className={`${item.pingo_journey == 'Disable' ? "hidden" : "block"} w-4 h-4 rounded-full ${item.pingo_journey == 'Essential' ? "bg-brown" : "bg-red"}`}></span>
                    <span className="sr-only">{item.pingo_journey}</span>
                  </dd>
                  <dt className={`ml-2 col-span-2 border-b border-gray pb-3 ${item.pingo_campus == 'Disable' ? "opacity-50" : ""}`}>Pingo Campus™</dt>
                  <dd className="col-span-1 mr-2 border-b border-gray pb-3 pl-3 flex items-center justify-center">
                    <span className={`${item.pingo_campus == 'Disable' ? "hidden" : "block"} w-4 h-4 rounded-full ${item.pingo_campus == 'Essential' ? "bg-brown" : "bg-red"}`}></span>
                    <span className="sr-only">{item.pingo_campus}</span>
                  </dd>
                  <dt className={`ml-2 col-span-2 border-b border-gray pb-3 ${item.call_center_booking == 'Disable' ? "opacity-50" : ""}`}>Pingo™ Call-Center Booking</dt>
                  <dd className="col-span-1 mr-2 border-b border-gray pb-3 pl-3 flex items-center justify-center">
                    <span className={`${item.call_center_booking == 'Disable' ? "hidden" : "block"} w-4 h-4 rounded-full ${item.call_center_booking == 'Essential' ? "bg-brown" : "bg-red"}`}></span>
                    <span className="sr-only">{item.call_center_booking}</span>
                  </dd>
                  <dt className={`ml-2 col-span-2 border-b border-gray pb-3 ${item.pre_booking == 'Disable' ? "opacity-50" : ""}`}>Guaranteed<br />Pre-booking</dt>
                  <dd className="col-span-1 mr-2 border-b border-gray pb-3 pl-3 flex items-center justify-center">
                    <span className={`${item.pre_booking == 'Disable' ? "hidden" : "block"} w-4 h-4 rounded-full ${item.pre_booking == 'Essential' ? "bg-brown" : "bg-red"}`}></span>
                    <span className="sr-only">{item.pre_booking}</span>
                  </dd>
                  <dt className={`ml-2 col-span-2 border-b border-gray pb-3 ${item.pingo_venues == 'Disable' ? "opacity-50" : ""}`}>Pingo<br />Venues™</dt>
                  <dd className="col-span-1 mr-2 border-b border-gray pb-3 pl-3 flex items-center justify-center">
                    <span className={`${item.pingo_venues == 'Disable' ? "hidden" : "block"} w-4 h-4 rounded-full ${item.pingo_venues == 'Essential' ? "bg-brown" : "bg-red"}`}></span>
                    <span className="sr-only">{item.pingo_venues}</span>
                  </dd>
                  <dt className={`ml-2 col-span-2 border-b border-gray pb-3 ${item.pingo_access == 'Disable' ? "opacity-50" : ""}`}>Pingo Access™ <span className="text-sm"><br />(Coming Soon)</span></dt>
                  <dd className="col-span-1 mr-2 border-b border-gray pb-3 pl-3 flex items-center justify-center">
                    <span className={`${item.pingo_access == 'Disable' ? "hidden" : "block"} w-4 h-4 rounded-full ${item.pingo_access == 'Essential' ? "bg-brown" : "bg-red"}`}></span>
                    <span className="sr-only">{item.pingo_access}</span>
                  </dd>
                </dl>
              </div>
            ))}
          </div>

        </div>


      </div>
    </section>
  )
}