import React, { Component } from 'react'
import styled from 'styled-components'
import { gsap } from 'gsap'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
// import home0101 from '../images/home-01-01.jpg'
// import home0102 from '../images/home-01-02.jpg'
// import home0103 from '../images/home-01-03.jpg'

const Section = styled.section`
  &.dark {
    background-color: #ebeae4;
  }
`

class BlockItems extends Component {
  componentDidMount() {
    const fadeUp = document.querySelectorAll('#block-home-01 .fade-up')

    fadeUp.forEach((element, index) => {
      gsap.from(element, {
        scrollTrigger: {
          trigger: element,
          start: 'top 90%',
        },
        duration: 0.5,
        opacity: '0',
        y: '20%',
      })
    })
  }

  render() {
    const { primary, items } = this.props.slice

    return (
      <Section
        id={`block-home-${this.props.index}`}
        className={`[ BlockItems ] relative  ${
          this.props.index === 1
            ? 'dark py-16 md:py-24'
            : 'pt-10 md:pt-24'
        }`}
      >
        <div className="container">
          {primary.heading &&
            <div className="grid grid-cols-4 md:grid-cols-12 md:mb-16">
              <div className="col-span-4 md:col-span-6">
                <h2
                  className={`h1 fade-up color--${
                    primary.heading_color === 'blue' ? 'primary' : 'black'
                  } fade-up`}
                >
                  {primary.heading}
                </h2>
              </div>
            </div>
          }

          {primary.content.text != null || primary.eyebrow != null ? (
            <div className={`grid grid-cols-4 md:grid-cols-12 ${primary.size == 'Case Study' ? 'gap-6 lg:gap-12' : ''}`}>
              {primary.eyebrow &&
                <div className="col-span-4 md:col-span-2">
                  <span className="eyebrow-name fade-up">{primary.eyebrow}</span>
                </div>
              }
              <div className={`col-span-4 ${primary.size == 'Case Study' ? 'md:col-span-7 md:col-start-5' : 'md:col-span-9 md:col-start-4 lg:col-span-6 lg:col-start-6'}`}>
                {primary.content.text &&
                  <p className="text-lg md:text-3xl fade-up mb-10">
                    {primary.content.text}
                  </p>
                }
                {primary.button_text ? (
                  <Link to={primary.button_link} className="btn--rounded">
                    {primary.button_text}
                  </Link>
                ) : (
                  ''
                )}
              </div>
            </div>
          ): (
            ''
          )}

          {items.map((item, index) =>
            index === 1 || index === 3 || index === 5 ? (
              <div
                key={index}
                className={`grid ${primary.size == 'Case Study' ? 'grid-cols-12 gap-6 lg:gap-12' : 'grid-cols-10 items-center'} ${index > 0 ? "mt-16 md:mt-20" : ""}`}
              >
                <div className={`col-span-10 mb-10 md:mb-0 ${primary.size == 'Case Study' ? 'order-2 md:col-span-7' : 'md:col-span-5'}`}>
                  <h3 className={`mb-6 opacity-50 fade-up ${primary.size === 'Default' ? "h2" : "text-xl md:text-2xl"}`}>{item.heading}</h3>
                  <div className="html-content md:text-lg fade-up">
                    <PrismicRichText field={item.content.richText}/>
                  </div>
                  {
                    index == (items.length - 1) ? (
                      primary.bottom_button_text ? (
                        <Link to={primary.bottom_button_link} className="btn--rounded mt-10">
                          {primary.bottom_button_text}
                        </Link>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )
                  }
                </div>
                <div className={`col-span-10 ${primary.size === 'Default' ? "md:col-span-4 md:col-start-6" : ""} ${primary.size === 'Small' ? "md:col-span-5 md:col-start-6" : ""} ${primary.size == 'Case Study' ? 'order-1 md:col-span-4' : ''}`}>
                  <GatsbyImage
                    image={item.image.gatsbyImageData}
                    alt={item.image.alt ? item.image.alt : ''}
                    className="radius-32 block mb-6 md:mb-0"
                  />
                </div>
              </div>
            ) : (
              <div
                key={index}
                className={`grid ${primary.size == 'Case Study' ? 'grid-cols-12 gap-6 lg:gap-12' : 'grid-cols-10'} ${index > 0 ? "mt-16 md:mt-20" : ""}`}
              >
                <div className={`mt-20 col-span-10 ${primary.size === 'Default' ? "md:col-span-5" : ""} ${primary.size === 'Small' ? "md:col-span-4" : ""} ${primary.size == 'Case Study' ? 'md:col-span-4' : 'order-2 md:order-1'}`}>
                  <GatsbyImage
                    image={item.image.gatsbyImageData}
                    alt={item.image.alt ? item.image.alt : ''}
                    className="radius-32 block mb-6 md:mb-0"
                  />
                </div>
                <div className={`col-span-10 ${primary.size === 'Default' ? "md:col-span-4 md:col-start-7" : ""} ${primary.size === 'Small' ? "md:col-span-5 md:col-start-6" : ""} ${primary.size == 'Case Study' ? 'md:col-span-7' : ''} order-1 md:order-2 mb-10 md:mb-0`}>
                  <h3 className={`mb-6 opacity-50 fade-up ${primary.size === 'Default' ? "h2" : "text-xl md:text-2xl"}`}>{item.heading}</h3>
                  <div className="html-content md:text-lg fade-up">
                    <PrismicRichText field={item.content.richText}/>
                  </div>
                  {
                    index == (items.length - 1) ? (
                      primary.bottom_button_text ? (
                        <Link to={primary.bottom_button_link} className="btn--rounded mt-10">
                          {primary.bottom_button_text}
                        </Link>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )
                  }
                </div>
              </div>
            )
          )}
        </div>
      </Section>
    )
  }
}
export default BlockItems
