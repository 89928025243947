import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { gsap } from 'gsap'
import bgPhoto from '../images/home-full-photo.jpg'
import { media } from '../styles/media'

const Section = styled.section`
  height: 528px;
  position: relative;
  overflow: hidden;
  background: var(--grey-2);
  @media ${media.mdUp} {
    height: 670px;
  }
  @media ${media.lgUp} {
    height: 800px;
  }
  .bg--photo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${bgPhoto}) no-repeat;
    background-position: top;
    background-size: cover;
    overflow: hidden;
  }
`

export default function BlockFullPhoto() {
  useEffect(() => {
    const bg = document.querySelector('.bg--photo')

    const bgTL = gsap.timeline({
      scrollTrigger: {
        trigger: '#block-photo',
        scrub: 1,
        start: 'top 90%',
      },
    })

    bgTL.fromTo(bg, { scale: 1.1 }, { scale: 1, force3D: true }, 0)
  }, [])

  return (
    <Section id="block-photo" className="relative mt-16 md:mt-24">
      <div className="bg--photo" />
    </Section>
  )
}
