import React from 'react'
import { PrismicRichText, PrismicLink } from '@prismicio/react'

export default function BlockComparison({ slice }) {

  const { primary, items } = slice

  console.log(`block comparison`, slice)

  return (
    <section
      className="[ BlockComparison ] mt-16 md:mt-24"
    >
      <div className="container relative">
        {primary.show_legend === true &&
          <div className="bg-gray inline-flex items-center p-4 rounded-lg mb-8">
            <span className="flex items-center font-medium leading-none mr-6"><span className="inline-block w-4 h-4 bg-brown rounded-full mr-2"></span><span>Essential</span></span>
            <span className="flex items-center font-medium leading-none"><span className="inline-block w-4 h-4 bg-red rounded-full mr-2"></span><span>Optional</span></span>
          </div>
        }
        <div className="overflow-x-auto clear-both">
          <table className="w-[550px] md:w-full">
            <thead>
              <tr>
                <th className="sticky left-0 bg--main w-44 lg:w-auto text-left text-2xl md:text-3xl !leading-none font-medium border-b border-gray py-3 pr-3" valign="bottom"><span className="opacity-50">{ primary.heading }</span></th>
                <th className="text-sm font-medium border-b border-gray p-3 lg:w-40" valign="bottom">Transit Providers</th>
                <th className="text-sm font-medium border-b border-gray p-3 lg:w-40" valign="bottom">Paratransit Providers</th>
                <th className="text-sm font-medium border-b border-gray p-3 lg:w-40" valign="bottom">Corporate</th>
                <th className="text-sm font-medium border-b border-gray py-3 pl-3 lg:w-40" valign="bottom">Universities</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item,idx) => (
                <tr key={`comp-${idx}`}>
                  <td className="sticky left-0 bg--main py-3 pr-3 lg:py-5 lg:pr-5 border-b border-gray font-medium lg:text-xl">
                    {item.item_link.url != null ? (
                      <PrismicLink className="transition-all hover:underline" field={item.item_link}>{item.name}</PrismicLink>
                    ) : (
                      <span className="opacity-50">{item.name}</span>
                    )}

                  </td>
                  <td className="p-3 lg:p-5 border-b border-gray">
                    {item.on_demand_transit != "Disable" &&
                      <span className={`block mx-auto w-4 h-4 rounded-full ${item.on_demand_transit == 'Essential' ? "bg-brown" : "bg-red"}`}></span>
                    }
                  </td>
                  <td className="p-3 lg:p-5 border-b border-gray">
                    {item.paratransit != "Disable" &&
                      <span className={`block mx-auto w-4 h-4 rounded-full ${item.paratransit == 'Essential' ? "bg-brown" : "bg-red"}`}></span>
                    }
                  </td>
                  <td className="p-3 lg:p-5 border-b border-gray">
                    {item.corporate != "Disable" &&
                      <span className={`block mx-auto w-4 h-4 rounded-full ${item.corporate == 'Essential' ? "bg-brown" : "bg-red"}`}></span>
                    }
                  </td>
                  <td className="py-3 pl-3 lg:p-5 border-b border-gray">
                    {item.university != "Disable" &&
                      <span className={`block mx-auto w-4 h-4 rounded-full ${item.university == 'Essential' ? "bg-brown" : "bg-red"}`}></span>
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  )
}