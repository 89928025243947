import React, { Component } from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import google from '../images/svg--google.svg'
import apple from '../images/svg--apple.svg'

const Section = styled.section`
  background-color: #ebeae4;
  .slick-slider {
    padding-left: 16px;
    overflow-x: hidden;
  }
  .slick-dots {
    position: relative;
    bottom: 12px;
    text-align: left;
    button {
      display: none !important;
    }
    li {
      cursor: pointer;
      width: 12px;
      height: 2px;
      display: inline-block;
      background: #292524;
      opacity: 0.2;
      &.slick-active {
        opacity: 1;
      }
    }
  }
  .slick-list {
    padding: 0 20% 0 0 !important;
  }
  .slick-slide {
    opacity: 0.15;
    transition: all 0.3s ease;
    &.slick-active {
      opacity: 1;
      transition: all 0.3s ease;
    }
  }
  .slick-center {
    transition: all 0.3s ease;
  }
  .slick-next {
    top: auto;
    bottom: -20px;
    right: 16px;
    width: 40px;
    height: 40px;
    z-index: 200;
  }
  .slick-prev:before,
  .slick-next:before {
    width: 37px;
    height: 37px;
    display: block;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg width='36' height='37' viewBox='0 0 36 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='36' y='36.4453' width='36' height='36' rx='18' transform='rotate(-180 36 36.4453)' fill='%23F3F2ED'/%3E%3Cg opacity='0.98'%3E%3Cg clip-path='url(%23clip0_0_3238)'%3E%3Cpath d='M10.5 19.3828L21.9094 19.3828L16.6687 24.6234L18 25.9453L25.5 18.4453L18 10.9453L16.6781 12.2672L21.9094 17.5078L10.5 17.5078L10.5 19.3828Z' fill='%23373736'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_0_3238'%3E%3Crect width='22.5' height='22.5' fill='white' transform='translate(29.25 29.6953) rotate(-180)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    content: '';
  }
`

class BlockHomeApps extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '10%',
      variableHeight: true,
    }

    const { primary, items } = this.props.slice
    const { global } = this.props

    return (
      <Section className="py-16 md:py-32">
        <div className="container relative">
          <div className="grid grid-cols-12 mb-10 md:mb-24">
            <div className="col-span-12 md:col-span-8">
              <h2 className="h1">{primary.heading}</h2>
            </div>
            <div className="col-span-4 hidden md:flex items-end justify-end">
              <a href={ !global ? '' : global.google_link } target="_blank" rel="noreferrer">
                <img src={google} alt="Get it on Google Play" />
              </a>
              <a
                href={ !global ? '' : global.apple_link }
                target="_blank"
                className="ml-2"
                rel="noreferrer"
              >
                <img src={apple} alt="Download on the App Store" loading="lazy" />
              </a>
            </div>
          </div>
        </div>
        <div className="md:hidden">
          <Slider {...settings}>
            {items.map((item,index) => (
              <div key={`item-${index}`}>
                <div className="pr-6 pb-10">
                  <h3 className="h2 mb-6 opacity-50">{item.heading}</h3>
                  <p className="mb-6">{item.content.text}</p>
                  <div
                    className="ul--lines"
                    dangerouslySetInnerHTML={{ __html: item.list.html }}
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="container relative">
          <div className="hidden md:grid grid-cols-12 gap-x-12 gap-y-2">
            {items.map((item,index) => (
              <div className="col-span-4" key={`heading-${index}`}>
                <h3 className="h2 mb-6 opacity-50">{item.heading}</h3>
              </div>
            ))}
            {items.map((item,index) => (
              <div className="col-span-4 mb-8" key={`text-${index}`}>
                <p className="text-lg">{item.content.text}</p>
              </div>
            ))}
            {items.map((item,index) => (
              <div className="col-span-4" key={`content-${index}`}>
                <div
                  className="ul--lines"
                  dangerouslySetInnerHTML={{ __html: item.list.html }}
                />
              </div>
            ))}
          </div>
        </div>
      </Section>
    )
  }
}
export default BlockHomeApps
