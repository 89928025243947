import React from "react";
import BlockList from "./BlockList";
import BlockApps from "./BlockApps";
import BlockButton from "./BlockButton";
import BlockFourUp from "./BlockFourUp";
import BlockHeroCareers from "./BlockHeroCareers";
import BlockItems from "./BlockItems";
import BlockGatedContent from "./BlockGatedContent";
import BlockFullPhoto from "./BlockFullPhoto";
import BlockHomePartners from "./BlockHomePartners";
import BlockTestimonials from "./BlockTestimonials";
import BlockHero from "./BlockHero";
import BlockHeroSimple from "./BlockHeroSimple";
import BlockCardLinkList from "./BlockCardLinkList";
import TimelineBlock from "./BlockTimeline";
import BlockLargePhoto from "./BlockLargePhoto";
import BlockHeroAbout from "./BlockHeroAbout";
import BlockLeadership from "./BlockLeadership";
import BlockHeroContact from "./BlockHeroContact";
import BlockHeroOverview from "./BlockHeroOverview";
import BlockHomeApps from "./BlockHomeApps";
import BlockHeroStories from "./BlockHeroStories";
import BlockPrivacy from "./BlockPrivacy";
import BlockMailchimp from "./BlockMailchimp";
import BlockSection from "./BlockSection";
import BlockCarousel from "./BlockCarousel";
import BlockPageNav from "./BlockPageNav";
import BlockImage from "./BlockImage";
import BlockContentColumns from "./BlockContentColumns";
import BlockMatrix from "./BlockMatrix";
import BlockComparison from "./BlockComparison";
import BlockEmbed from "./BlockEmbed";

const SliceZone = ({
  sliceZone,
  page,
  global,
  title,
  data,
  lang,
  altLangs,
  slug,
  path,
  pageContext,
  id,
  prismicId,
}) => {
  const sliceComponents = {
    block_apps: BlockApps,
    block_button: BlockButton,
    block_4_up: BlockFourUp,
    block_list: BlockList,
    hero_careers: BlockHeroCareers,
    block_items: BlockItems,
    block_gated_content: BlockGatedContent,
    block_full_photo: BlockFullPhoto,
    block_partners: BlockHomePartners,
    testimonials: BlockTestimonials,
    hero_home: BlockHero,
    timeline_block: TimelineBlock,
    block_large_photo: BlockLargePhoto,
    hero_about: BlockHeroAbout,
    hero_simple: BlockHeroSimple,
    card_link_list: BlockCardLinkList,
    leadership_blocks: BlockLeadership,
    hero_contact: BlockHeroContact,
    hero_overview: BlockHeroOverview,
    block_overview_apps: BlockHomeApps,
    hero_blog: BlockHeroStories,
    privacy_content: BlockPrivacy,
    mailchimp: BlockMailchimp,
    block_section: BlockSection,
    content_carousel: BlockCarousel,
    block_page_nav: BlockPageNav,
    block_image: BlockImage,
    block_content_columns: BlockContentColumns,
    blockmatrix: BlockMatrix,
    blockcomparison: BlockComparison,
    embed: BlockEmbed,
  };

  const sliceZoneContent = sliceZone.map((slice, index) => {
    if (slice) {
      const SliceComponent = sliceComponents[slice.slice_type];
      if (SliceComponent) {
        return (
          <SliceComponent
            id={id}
            prismicId={prismicId}
            lang={lang}
            altLangs={altLangs}
            path={path}
            pageContext={pageContext}
            slug={slug}
            slice={slice}
            key={`slice-${index}`}
            index={index}
            page={page}
            global={global}
            title={title}
            data={data}
          />
        );
      }
    }
    return null;
  });

  return sliceZoneContent;
};

export default SliceZone;
