import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { gsap } from 'gsap'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import twitter from '../images/twitter.svg'
import facebook from '../images/facebook.svg'
import instagram from '../images/instagram.svg'
import linkedin from '../images/linkedin.svg'
import arrow from '../images/circle-arrow.svg'

const Hero = styled.header``

class BlockStory extends Component {
  componentDidMount() {
    const fadeElements = gsap.timeline()
    fadeElements.to('#block-story', {
      opacity: 1,
      delay: 0.25,
    })
    fadeElements.from('#block-story .fade-up', {
      duration: 0.7,
      opacity: '0',
      y: '20%',
      stagger: {
        amount: '0.5',
      },
    })
  }

  isBrowser = () => typeof window !== 'undefined'

  getCurrURL = () => {
    const urlString = window.location.href
    const url = new URL(urlString)

    return url.href
  }

  render() {
    const { data, global, blogs } = this.props

    let blog_heading, blog_button_url
    if(!global) {
      const blog_heading_parts = data.slug.split("/")
      blog_heading = blog_heading_parts[0].charAt(0).toUpperCase() + blog_heading_parts[0].slice(1)
      blog_button_url = "/" + blog_heading_parts[0]
    }

    // console.log(blogs)
    const currentURL = this.isBrowser() ? this.getCurrURL() : ''

    return (
      <Hero id="block-story" className="container relative">
        <div className="relative pt-5 md:pt-16 md:mb-24">
          <div className="grid grid-cols-12 relative z-10">
            <div className="col-span-6 hidden md:block">
              <p className="h1 mb-20 color--primary fade-up">
                { !global ? blog_heading : global.blog_heading }
              </p>
              <div className="flex items-center">
                <div className="flex items-center mr-5">
                  <a
                    href={`https://twitter.com/intent/tweet?url=${currentURL}`}
                    className="mr-3"
                  >
                    <img src={twitter} alt="Twitter" loading="lazy" />
                  </a>
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${currentURL}`}
                    className="mr-3"
                  >
                    <img src={facebook} alt="Facebook" loading="lazy" />
                  </a>
                  <a
                    href={`https://www.linkedin.com/shareArticle?mini=true&url=${currentURL}`}
                    className="mr-3"
                  >
                    <img src={linkedin} alt="LinkedIn" loading="lazy" />
                  </a>
                </div>
                <Link
                  to={ !global ? blog_button_url : global.blog_button_url }
                  className="btn--rounded block"
                >
                  { !global ? 'View all articles' :global.blog_button }
                </Link>
              </div>
            </div>
            <div className="col-span-12 md:col-span-6">
              <div className="flex items-center justify-between mb-10 md:hidden">
                <div className="flex items-center">
                  <a
                    href={`https://twitter.com/intent/tweet?url=${currentURL}`}
                    className="mr-3"
                  >
                    <img src={twitter} alt="twitter" loading="lazy" />
                  </a>
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${currentURL}`}
                    className="mr-3"
                  >
                    <img src={facebook} alt="twitter" loading="lazy" />
                  </a>
                  <a
                    href={`https://www.linkedin.com/shareArticle?mini=true&url=${currentURL}`}
                    className="mr-3"
                  >
                    <img src={linkedin} alt="twitter" loading="lazy" />
                  </a>
                </div>
                <Link
                  to={ !global ? '' : global.blog_button_url }
                  className="btn--rounded block"
                >
                  { !global ? '' : global.blog_button }
                </Link>
              </div>

              <div className="flex flex-col">
                <GatsbyImage
                  image={data.image.gatsbyImageData}
                  alt={data.image.alt ? data.image.alt : ''}
                  className="radius-32 mb-8 block order-2 md:order-1"
                />
                <div className="flex justify-between w-full items-center order-1 md:order-2 mb-8">
                  <div>
                    {data.published_by !== null ? (
                      <div className="flex items-center">
                        <GatsbyImage
                          image={data.published_by_image.gatsbyImageData}
                          alt={data.published_by_image.alt ? data.published_by_image.alt : ''}
                          className="radius-32 block w-12 grayscale"
                        />
                        <div className="ml-4 mt-1">
                          <div className="text-xs uppercase">
                            {data.published_by}
                          </div>
                          <span className="text-xs">{data.date}</span>
                        </div>
                      </div>
                    ) : (
                      <>
                        <span className="mb-4 pill pill--gray uppercase">
                          {data.tag}
                        </span>
                        <span className="text-xs ml-4">{data.date}</span>
                      </>
                    )}
                  </div>
                  <span className="text-xs">Written by {data.author}</span>
                </div>

                <h1 className="h3 order-1 md:order-3 mb-10 md:mb-0">
                  {data.title.text}
                </h1>
              </div>
              <div className="content--story">
                {data.body.map((item, index) => (
                  <React.Fragment key={`content-${index}`}>
                    {item.slice_type === 'rich_text' ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.primary.content.html,
                        }}
                      />
                    ) : (
                      ''
                    )}{' '}
                    {item.slice_type === 'title' ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.primary.title1.html,
                        }}
                      />
                    ) : (
                      ''
                    )}
                    {item.slice_type === 'image_block' ? (
                      <>
                        {' '}
                        <GatsbyImage
                          image={item.primary.image1.gatsbyImageData}
                          alt={item.primary.image1.alt ? item.primary.image1.alt : ''}
                          className="radius-32 mt-20 mb-4 block"
                        />
                        <p className="caption">{item.primary.image_title}</p>
                      </>
                    ) : (
                      ''
                    )}
                    {item.slice_type === 'divider' ? <hr /> : ''}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>

        <section className="container relative pt-16 md:pt-20 pb-10">
          <div className="grid grid-cols-12 mb-10">
            <div className="mb-6 md:mb-0 col-span-12 md:col-span-8 lg:col-span-5">
              <h2 className="h2">View More</h2>
            </div>
            <div className="col-span-12 md:col-span-4 lg:col-span-6 lg:col-start-7 flex md:justify-end items-start">
              <Link to={ !global ? blog_button_url : global.blog_button_url } className="btn--rounded block">
                { !global ? 'View all articles' : global.blog_button }
              </Link>
            </div>
          </div>
          <div className="grid grid-cols-7 gap-x-12">
            { !blogs ? '' : blogs.edges.map((item, index) => (
              <div
                className={`col-span-7 md:col-span-${
                  index === 1 ? 3 : 2
                } mb-16 md:mb-0 ${
                  index === 0 ? 'md:mt-10' : index === 1 ? 'md:mt-28' : ''
                }`}
                key={`blog-${index}`}
              >
                <Link to={`/${item.node.data.slug}`} className="hover-zoom">
                  <div className="relative mb-8">
                    <div className="hover-zoom-img">
                      <GatsbyImage
                        image={item.node.data.image.gatsbyImageData}
                        alt={item.node.data.image.alt ? item.node.data.image.alt : ''}
                        className="radius-32 block"
                      />
                    </div>
                    <img
                      src={arrow}
                      alt=""
                      className="absolute right-5 bottom-5"
                      loading="lazy"
                    />
                  </div>
                  <span className="mb-4 pill pill--gray">
                    {item.node.data.date}
                  </span>
                  <h3 className="text-lg font-medium mb-4">
                    {item.node.data.title.text}
                  </h3>
                  <p>{item.node.data.description}</p>
                </Link>
              </div>
            ))}
          </div>
        </section>
      </Hero>
    )
  }
}
export default BlockStory
