/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'
import Layout from './src/components/layout'

import { linkResolver } from './linkresolver'
import Page from './src/templates/page'
import Blog from './src/templates/blog'

gsap.registerPlugin(ScrollTrigger)

//export function wrapPageElement({ element, props }) {
export const wrapPageElement = ({ element, props }) => (
    <PrismicPreviewProvider
      repositoryConfigs={[
        {
          repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
          linkResolver,
          componentResolver: componentResolverFromMap({
            page: Page,
            blog: Blog,
          }),
        },
      ]}
    >
      <Layout {...props}>{element}</Layout>
    </PrismicPreviewProvider>
)
