import React, { useRef } from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicLink } from '@prismicio/react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import arrow from '../images/circle-arrow-dark.svg'


const Section = styled.section`
  .slick-slide {
    visibility: hidden;

    &.slick-active {
      visibility: visible;
    }
  }
`


const BlockCarousel = ({ slice }) => {
  const sliderRef = useRef();
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const { primary, items } = slice

  const totalSlides = slice.items.length

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    fade: true,
    afterChange: function(index) {
      setCurrentSlide(index) // needed for swipe and updating pager
    },
    responsive: [
      {
        breakpoint: 720,
        settings: {
          centerMode: true,
          centerPadding: '3%',
        },
      },
    ],
  }

  let carouselWidth = `md:col-span-8 md:col-start-5`
  if( primary.style === "Full Width" ) {
    carouselWidth = ``
  } else if( primary.eyebrow && primary.mobile_screenshot ) {
    carouselWidth = `sm:col-span-6 md:col-span-3 md:col-start-6`
  } else if( primary.eyebrow && !primary.mobile_screenshot ) {
    carouselWidth = `md:col-span-8 md:col-start-5`
  }

  const handlePrevSlide = () => {
    sliderRef?.current?.slickPrev()
    currentSlide === 0 ? setCurrentSlide(totalSlides - 1) : setCurrentSlide(currentSlide - 1)
  }
  const handleNextSlide = () => {
    sliderRef?.current?.slickNext()
    currentSlide === totalSlides - 1 ? setCurrentSlide(0) : setCurrentSlide(currentSlide + 1)
  }

  return (
    <Section className="[ BlockCarousel ] container relative pt-16 md:pt-24">
      <div className="grid grid-cols-12 gap-x-6">
        {primary.eyebrow &&
          <div className="hidden md:block col-span-12 md:col-span-2">
            <span className="eyebrow-name">{primary.eyebrow}</span>
          </div>
        }
        <div className={`col-span-12 ${carouselWidth}`} role="region" aria-label="carousel">
          <Slider ref={sliderRef} {...settings}>
            {items.map((item, index) => (
              <div className="" key={`slide-${index}`}>
                <GatsbyImage
                  image={item.image.gatsbyImageData}
                  alt={item.image.alt||''}
                  className={primary.style === 'Full Width' ? 'radius-32' : 'drop-shadow '}
                />
                { item.title1 != null || item.description1 != null ? (
                  <div className="grid grid-cols-12 mt-10">
                    <div className="col-span-12 md:col-span-6 md:pt-10">
                      { item.title1 != null &&
                        <h2 className="font-medium text-lg mb-4">{item.title1}</h2>
                      }
                      { item.description1 != null &&
                        <p>{item.description1}</p>
                      }
                      {item.button_1_link && item.button_1_label &&
                        <div className="flex items-center mt-6 md:mt-8">
                          <PrismicLink className="btn--rounded bg--brown" field={item.button_1_link}>{item.button_1_label}</PrismicLink>
                          {item.button_2_link && item.button_2_label &&
                            <PrismicLink className="btn--rounded ml-4" field={item.button_2_link}>{item.button_2_label}</PrismicLink>
                          }
                        </div>
                      }
                    </div>
                    <div className="col-span-12 md:col-span-6">
                    { items.length > 1 &&
                      <div className="flex items-center justify-end">
                        <button type="button" className={`block w-8 h-8 rotate-180 ${currentSlide === 0 ? "opacity-20" : ""}`} onClick={() => handlePrevSlide()} disabled={currentSlide === 0 ? true : null}>
                          <span className="sr-only">Previous slide</span>
                          <img src={arrow} alt="" loading="lazy" />
                        </button>

                        <ul className="flex items-center mx-2">
                        {items.map((item, index) => (
                          <li className="mx-1" key={`nav-${index}`}>
                            <button type="button" className={`block bg-black w-3.5 h-0.5 ${currentSlide != index ? "opacity-20" : ""}`} onClick={() => sliderRef?.current?.slickGoTo(index)}>
                              <span className="sr-only">Go to slide {index}</span>
                            </button>
                          </li>
                        ))}
                        </ul>

                        <button type="button" className={`block w-8 h-8 ${currentSlide === totalSlides - 1 ? "opacity-20" : ""}`} onClick={() => handleNextSlide()}>
                          <span className="sr-only">Next slide</span>
                          <img src={arrow} alt="" loading="lazy" />
                        </button>
                      </div>
                    }
                    </div>
                  </div>
                ) : ( "" )}
              </div>
            ))}
          </Slider>
          { items.length > 1 && primary.style != 'Full Width' &&
            <div className="flex items-center justify-end mt-2">
              <button type="button" className={`block w-8 h-8 rotate-180 ${currentSlide === 0 ? "opacity-20" : ""}`} onClick={() => handlePrevSlide()} disabled={currentSlide === 0 ? true : null}>
                <span className="sr-only">Previous slide</span>
                <img src={arrow} alt="" loading="lazy" />
              </button>

              <ul className="flex items-center mx-2">
              {items.map((item, index) => (
                <li className="mx-1" key={`nav-${index}`}>
                  <button type="button" className={`block bg-black w-3.5 h-0.5 ${currentSlide != index ? "opacity-20" : ""}`} onClick={() => sliderRef?.current?.slickGoTo(index)}>
                    <span className="sr-only">Go to slide {index}</span>
                  </button>
                </li>
              ))}
              </ul>

              <button type="button" className={`block w-8 h-8 ${currentSlide === totalSlides - 1 ? "opacity-20" : ""}`} onClick={() => handleNextSlide()}>
                <span className="sr-only">Next slide</span>
                <img src={arrow} alt="" loading="lazy" />
              </button>
            </div>
          }
        </div>
      </div>
    </Section>
  )
}


export default BlockCarousel
