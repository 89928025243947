import React, { Component } from 'react'
import styled from 'styled-components'
import { gsap } from 'gsap'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import logo from '../images/the-routing-company.svg'
import hero from '../images/home--hero.jpg'
import play from '../images/play.svg'
import playXs from '../images/play-xs.svg'
import close from '../images/close.svg'
import { media } from '../styles/media'
import videoExplainer from '../video/CloudKitchens_Explainer.mp4'
import about from '../images/about--hero.jpg'

const Hero = styled.header`
  .bg--hero {
    background-repeat: no-repeat;
    background-position: right bottom;
    background-image: url(${hero});
    background-size: cover;
    border-radius: 1000px 1000px 0 0;
  }
  .video--holder {
    height: 0;
    padding-bottom: 56%;
    background: linear-gradient(
      180deg,
      var(--grey-2) 0,
      var(--grey-2) 50%,
      var(--primary) 50%,
      var(--primary) 100%
    );
    video {
      margin-left: 32px;
      object-fit: cover;
    }
    @media ${media.mdUp} {
      padding-bottom: 67%;
    }
    @media ${media.lgUp} {
      height: calc(100vh - 68px);
      min-height: 620px;
      padding-bottom: 0;
      background: none;
      video {
        margin-left: 0;
      }
    }
  }
  .modal {
    background: var(--black);
    .video--holder {
      video {
        bottom: 0;
        margin: auto;
        object-fit: contain;
        height: auto;
        @media ${media.lgUp} {
          object-fit: cover;
        }
      }
    }
  }
`

class BlockHeroAbout extends Component {
  constructor(props) {
    super(props)
    this.state = { active: false }
  }

  componentDidMount() {
    console.log(`primary.video`, this.props)

    const fadeElements = gsap.timeline()
    fadeElements.to('#block-hero-about', {
      opacity: 1,
      delay: 0.25,
    })
    fadeElements.from('#block-hero-about .fade-up', {
      duration: 0.7,
      opacity: '0',
      y: '20%',
      stagger: {
        amount: '0.5',
      },
    })
  }
  
  

  toggleModal = () => {
    const html = document.querySelector('html')
    const wrapper = document.querySelector('.wrapper')
    const navHolder = document.querySelector('.nav--holder')
    const htmlActive = document.querySelector('html.is-scroll-disabled')
    const videoPlayer = document.querySelector('#video--hero')
    const modalBg = document.querySelector('.video--bg')

    if (htmlActive) {
      this.setState({ active: false })
      html.classList.remove('is-scroll-disabled')
      // const scrollY = wrapper.style.top
      // wrapper.style.top = ''
      // window.scrollTo(0, parseInt(scrollY || '0') * -1)
      navHolder.classList.remove('nav-up')
      modalBg.classList.remove('is-active')

      videoPlayer.pause()
    } else {
      this.setState({ active: true })
      videoPlayer.play()
      navHolder.classList.add('nav-up')
      modalBg.classList.add('is-active')

      setTimeout(() => {
        // wrapper.style.top = `-${document.documentElement.scrollTop}px`
        html.classList.add('is-scroll-disabled')
      }, 500)
      setTimeout(() => {
        navHolder.classList.add('nav-up')
      }, 1000)
    }
  }

  render() {
    const { primary, items } = this.props.slice

    return (
      <Hero id="block-hero-about" className="container relative">
        <div className="grid grid-cols-12 pt-10 md:pt-16">
          <div className="col-span-12 md:col-span-6">
            <h1 className="h1 md:mb-10 color--primary fade-up">
              {primary.heading}
            </h1>
          </div>
          <div className="col-span-6 hidden md:flex justify-end items-start">
            <img
              src={logo}
              alt="The Routing Company"
              className="fade-up w-10 logo"
            />
          </div>
        </div>
        <div className="grid grid-cols-12 md:mt-10 mb-10 md:mb-24">
          <div className="col-span-12 md:col-span-2">
            <span className="eyebrow-name fade-up">{primary.eyebrow}</span>
          </div>
          <div className="col-span-12 md:col-span-7 md:col-start-6">
            <p className="text-lg md:text-3xl fade-up">{primary.content.text}</p>
          </div>
        </div>
        { primary.video?.url &&
          <a
            onClick={this.toggleModal}
            className="relative block mb-16 md:mb-24 hover-zoom"
          >
            <StaticImage
              src="../images/about--hero.jpg"
              loading="eager"
              quality={95}
              formats={['auto', 'webp', 'avif']}
              alt=""
              className="radius-56 block hover-zoom-img"
            />
            <img
              src={play}
              alt=""
              className="absolute bottom-8 left-8 hidden md:block"
            />
            <img
              src={playXs}
              alt=""
              className="absolute bottom-3 left-3 md:hidden"
            />
            <div className="absolute bottom-4 md:bottom-8 right-5 md:right-12 text-lg text-white font-medium">
              {primary.video_title}
            </div>
          </a>
        }
        { !primary.video?.url &&
          <GatsbyImage
            image={primary.image.gatsbyImageData}
            alt={primary.image.alt||''}
            className="radius-56 block mb-10"
          />
        }

        <div className="grid grid-cols-12 md:gap-x-10">
          <div className="col-span-12">
            <p className="text-lg mb-5 font-medium">{primary.sub_heading}</p>
          </div>
          <div className="col-span-12 md:col-span-6">
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: primary.content_1.html }}
            />
          </div>
          <div className="col-span-12 md:col-span-6">
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: primary.content_2.html }}
            />
          </div>
        </div>
        { primary.video?.url &&
          <div
            className={`${
              this.state.active ? 'is-active' : ''
            } modal modal--video modal--video--hero`}
          >
            <div className="pt-6 absolute top-0 right-6 left-0 z-20">
              <div className="text-right">
                <a onClick={this.toggleModal} className="inline-block">
                  <img src={close} alt="Close video" />
                </a>
              </div>
            </div>
            <div className="video--holder">
              <video
                id="video--hero"
                src={primary.video.url ? primary.video.url : videoExplainer}
                type="video/mp4"
                placeholder={about}
                controls
              />
              <div className="video--bg" onClick={this.toggleModal} />
            </div>
          </div>
        }
      </Hero>
    )
  }
}
export default BlockHeroAbout
