import React, { Component } from 'react'
import { PrismicLink } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import arrow from '../images/circle-arrow-reverse.svg'

class BlockList extends Component {

  render() {
    const { primary, items } = this.props.slice

    // console.log(`block list`, primary)

    return (
      <section
        className={`[ BlockList ] mt-16 md:mt-24 ${primary.background === 'Dark' ? "bg--primary text-white py-16 md:py-24" : ""}`}
      >
        <div className="container relative">
          {primary.heading != null && primary.eyebrow != null ? (
            <div className="grid grid-cols-12 mb-10 md:mb-20">
              {primary.eyebrow != null &&
                <div className="col-span-12 md:col-span-2">
                  <span className={`eyebrow-name fade-up ${primary.background === 'Dark' ? "white" : ""}`}>{primary.eyebrow}</span>
                </div>
              }
              {primary.heading != null && primary.content == null &&
                <div className="col-span-12 md:col-span-5 md:col-start-5">
                  <h2 className="text-lg md:text-2xl fade-up">
                    <span className="block" dangerouslySetInnerHTML={{ __html: primary.heading }} />
                  </h2>
                </div>
              }
              {primary.heading != null && primary.content != null &&
                <div className="col-span-12 md:col-span-8 md:col-start-5">
                  <h2 className="text-lg md:text-2xl fade-up">
                    <span className="block" dangerouslySetInnerHTML={{ __html: primary.heading }} />
                  </h2>
                  <div className="content [ mt-6 ]">
                    <PrismicRichText field={primary.content.richText}/>
                  </div>
                </div>
              }
            </div>
          ) : ( '' )}
          <div className="grid grid-cols-12">
          {primary.heading === null && primary.eyebrow != null ? (
            <div className="col-span-12 md:col-span-2">
              <span className={`eyebrow-name fade-up ${primary.background === 'Dark' ? "white" : ""}`}>{primary.eyebrow}</span>
            </div>
          ) : ( '' )}
            <div className="col-span-12 md:col-span-8 md:col-start-5">
              <div className={`grid grid-cols-4 md:grid-cols-8 gap-y-6 md:gap-y-10 gap-x-12 ${primary.columns > 2 ? "lg:grid-cols-12" : ""}`}>
                {items.map((item,index) => {
                  let borderClasses = ""
                  if( primary.style === "List" ) {
                    borderClasses = "border-b"
                    if( index + 1 === items.length ) {
                      borderClasses = "md:border-b"
                    }
                    if( primary.background === 'Dark' ) {
                      borderClasses = `${borderClasses} border-clearwhite`
                    }
                    if( primary.background === 'Light' ) {
                      borderClasses = `${borderClasses} border-gray`
                    }
                  }
                  return(
                    <div className={`col-span-4 pb-4 ${borderClasses}`} key={`list-${index}`}>
                      {item.icon.url != null &&
                        <GatsbyImage
                          image={item.icon.gatsbyImageData}
                          alt={item.icon.alt ? item.icon.alt : ''}
                          width="44" height="44"
                          className="mb-4 w-11 h-11"
                        />
                      }
                      {item.heading &&
                        <h3 className={`text-lg font-medium ${item.divider === true && primary.background === 'Dark' ? "h-divide h-divide--lt" : "mb-4"} ${item.divider === true && primary.background === 'Light' ? "h-divide" : "mb-4"}`}>
                          {item.heading}
                        </h3>
                      }
                      <div
                        className="text-md html-content"
                        dangerouslySetInnerHTML={{ __html: item.content.html }}
                      />
                      {item.button_1_link &&
                        <PrismicLink className={`btn--arrow inline-block text-white font-medium mt-4 md:mt-6 ${primary.background === 'Dark' ? "bg--tan text-brown" : ""}`} field={item.button_1_link}>
                          { item.button_1_label &&
                            <span className="inline-block pointer-events-none">{item.button_1_label}</span>
                          }
                          <img className="inline-block pointer-events-none ml-2" src={arrow} alt="" />
                        </PrismicLink>
                      }
                    </div>
                  )
                })}
              </div>
            </div>
            {primary.button_1_link && primary.button_label &&
              <div className={`col-span-12 md:col-span-8 md:col-start-5 mt-16 ${primary.style === "Default" && primary.background != 'Dark' ? "pt-10 border-t border-gray" : ""}`}>
                <PrismicLink className={`btn--rounded ${primary.background === 'Dark' ? "bg--tan text-brown" : ""}`} field={primary.button_1_link}>{primary.button_label}</PrismicLink>
                {primary.button_2_link && primary.button_2_label &&
                  <PrismicLink className={`btn--rounded ml-4 lg:ml-8 ${primary.background === 'Dark' ? "outline--tan text-gray" : ""}`} field={primary.button_2_link}>{primary.button_2_label}</PrismicLink>
                }
              </div>
            }
            {primary.cta_description &&
              <div className={`col-span-12 md:col-span-8 md:col-start-5 mt-16 ${primary.style === "Default" && primary.background != 'Dark' ? "pt-10 border-t border-gray" : ""}`}>
                <PrismicRichText field={primary.cta_description.richText}/>
              </div>
            }
          </div>
        </div>
      </section>
    )
  }
}
export default BlockList
