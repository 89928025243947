import React, { Component } from 'react'
import styled from 'styled-components'
import { gsap } from 'gsap'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

class BlockFourUp extends Component {
  componentDidMount() {
    const fadeUp = document.querySelectorAll('#block-four-up .fade-up')

    fadeUp.forEach((element, index) => {
      gsap.from(element, {
        scrollTrigger: {
          trigger: element,
          start: 'top 90%',
        },
        duration: 0.5,
        opacity: '0',
        y: '20%',
      })
    })
  }

  render() {
    const { primary, items } = this.props.slice

    return (
      <div className="bg--primary pt-10 pb-20 md:pt-24 md:pb-24">
        <section id="block-four-up" className="container relative">
          <div className="grid grid-cols-12">
            <div className="col-span-12 md:col-span-2">
              <span className="eyebrow-name fade-up white">
                {primary.eyebrow}
              </span>
            </div>
            <div className="col-span-12 md:col-span-7 md:col-start-4">
              <p className="text-lg md:text-3xl fade-up text-white pb-10">
                {primary.content.text}
              </p>
              <Link to={primary.button_link} className="btn--rounded bg--tan">
                {primary.button_text}
              </Link>

              <div className="grid grid-cols-7">
                {items.map((item, index) => (
                  <div
                    className={`col-span-7 md:col-span-3 pt-16 ${
                      index === 1 || index === 3 ? 'md:col-start-5' : ''
                    }`}
                    key={`fourup${index}`}
                  >
                    <GatsbyImage
                      image={item.icon.gatsbyImageData}
                      alt={item.icon.alt||''}
                      className="mb-4 w-11 block"
                    />
                    <p className="text-lg fade-up text-white mb-2">
                      {item.heading}
                    </p>
                    <p className="text-white text-md md:pr-16 opacity-70">
                      {item.content_block}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
export default BlockFourUp
