import React, { Component } from 'react'
import styled from 'styled-components'
import { gsap } from 'gsap'
import { Link } from 'gatsby'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'
import home0101 from '../images/home-01-01.jpg'
import play from '../images/play-sm.svg'
import close from '../images/close.svg'
import { media } from '../styles/media'
import videoExplainer from '../video/CloudKitchens_Explainer.mp4'
import logo from '../images/the-routing-company.svg'

const Section = styled.section`
  .video--holder {
    height: 0;
    padding-bottom: 56%;
    background: linear-gradient(
      180deg,
      var(--grey-2) 0,
      var(--grey-2) 50%,
      var(--primary) 50%,
      var(--primary) 100%
    );
    video {
      bottom: 0;
      margin: auto;
      @media ${media.lgUp} {
        object-fit: cover;
      }
    }
    @media ${media.mdUp} {
      padding-bottom: 67%;
    }
    @media ${media.lgUp} {
      height: calc(100vh - 68px);
      min-height: 620px;
      padding-bottom: 0;
      background: none;
    }
  }
  .modal {
    background: var(--black);
    .video--holder {
      video {
        top: 0;
        bottom: 0;
        margin: auto;
        object-fit: cover;
        height: auto;
        @media ${media.lgUp} {
          object-fit: cover;
        }
      }
    }
  }
`

class BlockHeroCareers extends Component {
  constructor(props) {
    super(props)
    this.state = { active: false }
  }

  componentDidMount() {
    const fadeUp = document.querySelectorAll('#block-hero-careers .fade-up')

    fadeUp.forEach((element, index) => {
      gsap.from(element, {
        scrollTrigger: {
          trigger: element,
          start: 'top 90%',
        },
        duration: 0.5,
        opacity: '0',
        y: '20%',
      })
    })
  }

  toggleModal = () => {
    const html = document.querySelector('html')
    const wrapper = document.querySelector('.wrapper')
    const navHolder = document.querySelector('.nav--holder')
    const htmlActive = document.querySelector('html.is-scroll-disabled')
    const videoPlayer = document.querySelector('#video--hero')
    const modalBg = document.querySelector('.video--bg')

    if (htmlActive) {
      this.setState({ active: false })
      html.classList.remove('is-scroll-disabled')
      const scrollY = wrapper.style.top
      wrapper.style.top = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
      navHolder.classList.remove('nav-up')
      modalBg.classList.remove('is-active')

      videoPlayer.pause()
    } else {
      this.setState({ active: true })
      videoPlayer.play()
      navHolder.classList.add('nav-up')
      modalBg.classList.add('is-active')

      setTimeout(() => {
        wrapper.style.top = `-${document.documentElement.scrollTop}px`
        html.classList.add('is-scroll-disabled')
      }, 500)
      setTimeout(() => {
        navHolder.classList.add('nav-up')
      }, 1000)
    }
  }

  render() {
    const { primary, items } = this.props.slice

    return (
      <>
        <Section
          id="block-hero-careers"
          className="[ BLockHeroCareers1 ] container relative pt-10 md:pt-16"
        >
          <div className="grid grid-cols-12">
            <div className="col-span-12 hidden md:block mb-10">
              <div className="flex justify-end">
                <img
                  src={logo}
                  alt="The Routing Company"
                  className="fade-up logo"
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 md:mb-16">
            <div className="col-span-12 md:col-span-6">
              <h2 className="h1 fade-up color--primary fade-up">
                {primary.heading}
              </h2>
            </div>
            {/* <div className="col-span-6 hidden md:flex justify-end items-end">
              <a href="#jobs" className="btn--rounded">
                {primary.button_text}
              </a>
            </div> */}
          </div>
          <div className="grid grid-cols-12">
            <div className="col-span-12 md:col-span-2 mt-8 md:mt-0">
              <span className="eyebrow-name fade-up">{primary.eyebrow}</span>
            </div>
            <div className="col-span-12 md:col-span-6 md:col-start-6">
              <p className="text-lg md:text-3xl fade-up">
                {primary.content.text}
              </p>
            </div>
          </div>
        </Section>

        <Section className="[ BLockHeroCareers2 ] container">
          <div className="grid grid-cols-12 gap-x-6 mt-10 md:mt-24">
            <div className="col-span-12 md:col-span-7">
              <a onClick={this.toggleModal} className="relative block">
                <GatsbyImage
                  image={primary.image_1.gatsbyImageData}
                  alt={primary.image_1.alt ? primary.image_1.alt : ''}
                  className="radius-32 block mb-6"
                />
                <img src={play} alt="" className="absolute bottom-6 left-6" />
                <div className="absolute bottom-6 right-8 text-lg text-white w-7/12 md:w-auto">
                  {primary.video_title}
                </div>
              </a>
              <div className="hidden md:flex justify-center">
                <div className="w-7/12">
                  <GatsbyImage
                    image={primary.image_2.gatsbyImageData}
                    alt={primary.image_2.alt ? primary.image_2.alt : ''}
                    className="radius-32 block mb-6"
                  />
                </div>
              </div>
            </div>
            <div className="hidden md:block col-span-7 md:col-span-5">
              <GatsbyImage
                image={primary.image_3.gatsbyImageData}
                alt={primary.image_3.alt ? primary.image_3.alt : ''}
                className="radius-32 block md:mt-20 mb-6"
              />
            </div>
          </div>
        </Section>

        <div className="grid grid-cols-12 gap-6 md:hidden">
          <div className="col-span-7">
            <GatsbyImage
              image={primary.image_2.gatsbyImageData}
              alt={primary.image_2.alt ? primary.image_2.alt : ''}
              className="radius-rb-32 block mb-6"
            />
          </div>
          <div className="col-span-5 md:col-span-5">
            <GatsbyImage
              image={primary.image_3.gatsbyImageData}
              alt={primary.image_3.alt ? primary.image_3.alt : ''}
              className="radius-lb-32 block md:mt-20 mb-6"
            />
          </div>
        </div>

        <Section
          className={`${
            this.state.active ? 'is-active' : ''
          } [ BlockHeroCareers3 ] modal modal--video modal--video--hero`}
        >
          <div className="pt-6 absolute top-0 right-6 left-0 z-20">
            <div className="text-right">
              <a onClick={this.toggleModal} className="inline-block">
                <img src={close} alt="Close video" />
              </a>
            </div>
          </div>
          <div className="video--holder">
            <video
              id="video--hero"
              src={primary.video.url ? primary.video.url : videoExplainer}
              type="video/mp4"
              placeholder={home0101}
              controls
            />
            <div className="video--bg" onClick={this.toggleModal} />
          </div>
        </Section>
      </>
    )
  }
}
export default BlockHeroCareers
