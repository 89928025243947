import React, { useEffect } from 'react'
import { graphql } from 'gatsby'

import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import Seo from '../components/seo'
import SliceZone from '../components/SliceZone'
import BlockHomeStories from '../components/BlockHomeStories'
// import BlockJobs from '../components/BlockJobs'
// import BlockStories from '../components/BlockStories'
// import SearchResults from '../components/SearchResults'
import Search from '../components/Search'
// const searchIndices = [{ name: `theroutingcompanydotcomNewsroom`, title: `Newsroom` }, { name: `theroutingcompanydotcomPages`, title: `Pages` }]
const searchIndices = [{ name: `theroutingcompanydotcom`, title: `Pages` }]
// import BlockTimeline from '../components/BlockTimeline'
const Page = ({ data, pageContext, path }) => {

  // console.log(`page`, data)
  let doc, blogContent, global, lang, altLangs, id, prismicId

  if (!data.page) {
    doc = data.prismicPage.data
  } else {
    doc = data.page.edges[0].node.data
    blogContent = data.blog
    global = data.global.edges[0].node.data
    lang = data.page.edges[0].node.lang
    altLangs = data.page.edges[0].node.alternate_languages
    id = data.page.edges[0].node.id
    prismicId = data.page.edges[0].node.prismicId
    // console.log('DATAPAGE')
    // console.log(data.page.edges[0].node.data.image?.url)
  }

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  
  return (
    <>
      <Seo
        title={doc.title}
        description={doc.description}
        keywords={doc.keywords}
        data={
          {
            image: {
              url: data.page.edges[0].node.data.image?.url
            }
          }
        }
      />
      <SliceZone
        id={id}
        prismicId={prismicId}
        lang={lang}
        altLangs={altLangs}
        path={path}
        pageContext={pageContext}
        slug={doc.slug}
        sliceZone={doc.body}
        page={doc.page}
        global={global}
        title={doc.title}
        data={blogContent}
      />
      {doc.page === 'home' ? (
        <BlockHomeStories
          title="Newsroom"
          size="h1"
          columns="6"
          data={blogContent}
          global={global}
        />
      ) : (
        ''
      )}
      {/* {doc.page === 'careers' ? <BlockJobs /> : ''} */}

      {doc.page === 'search' ? <Search indices={searchIndices}/> : ''}

      {/* {doc.page === 'about' ? <BlockTimeline /> : ''} */}
      {/* {console.log(`Page: ${doc.page}`)} */}
    </>
  )
}

export const query = graphql`
  query Pages($slug: String!) {
    global: allPrismicGlobalVariables {
      edges {
        node {
          _previewable
          data {
            blog_button
            blog_button_url
            blog_heading
            apple_link
            google_link
            brochure_link {
              url
            }
          }
        }
      }
    }
    blog: allPrismicBlog(sort: { order: DESC, fields: data___date }) {
      edges {
        node {
          _previewable
          alternate_languages {
            id
            type
            lang
            uid
          }
          lang
          data {
            slug
            date(formatString: "MMM D, YYYY")
            description
            body {
              ... on PrismicBlogDataBodyDivider {
                id
                slice_label
                slice_type
                primary {
                  divider
                }
              }
              ... on PrismicBlogDataBodyImageBlock {
                id
                slice_label
                slice_type
                primary {
                  image_title
                  image1 {
                    alt
                    url
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  }
                }
              }
              ... on PrismicBlogDataBodyRichText {
                id
                slice_label
                slice_type
                primary {
                  content {
                    html
                    text
                    richText
                  }
                }
              }
              ... on PrismicBlogDataBodyTitle {
                id
                slice_label
                slice_type
                primary {
                  title1 {
                    html
                    richText
                    text
                  }
                }
              }
            }
            title {
              html
              richText
              text
            }
            published_by_image {
              url
              alt
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
            image {
              url
              alt
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
            published_by
            author
            tag
          }
        }
      }
    }
    page: allPrismicPage(filter: { data: { slug: { eq: $slug } } }) {
      edges {
        node {
          _previewable
          alternate_languages {
            id
            type
            lang
            uid
          }
          lang
          id
          prismicId
          data {
            body {
              ... on PrismicPageDataBodyMailchimp {
                id
                slice_label
                slice_type
                items {
                  newsletter_date
                  newsletter_link {
                    url
                  }
                  newsletter_title
                }
              }
              ... on PrismicPageDataBodyHeroBlog {
                id
                slice_label
                slice_type
                primary {
                  heading
                  content {
                    html
                    richText
                    text
                  }
                  media_kit_label
                  media_kit {
                    url
                  }
                }
              }
              ... on PrismicPageDataBodyBlock4Up {
                id
                primary {
                  content {
                    html
                    text
                    richText
                  }
                  eyebrow
                  button_text
                  button_link
                }
                slice_label
                slice_type
                items {
                  number
                  content_block
                  heading
                  icon {
                    alt
                    url
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  }
                }
              }
              ... on PrismicPageDataBodyCardLinkList {
                id
                slice_label
                slice_type
                items {
                  picture {
                    alt
                    url
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  }
                  text {
                    html
                    richText
                    text
                  }
                  link_text {
                    text
                  }
                  link {
                    raw
                    document {
                      ... on PrismicPage {
                        data {
                          slug
                        }
                      }
                    }
                    slug
                    url
                    link_type
                  }
                }
              }
              ... on PrismicPageDataBodyBlockGatedContent {
                id
                slice_label
                slice_type
                primary {
                  page_heading
                  page_heading_color
                  eyebrow
                  image1 {
                    url
                    alt
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  }
                  content_heading
                  content {
                    richText
                  }
                  call_to_action {
                    richText
                  }
                  gated_content {
                    richText
                  }
                  button_label
                  download_button_label
                  download_pdf_link {
                    url
                  }
                }
              }
              ... on PrismicPageDataBodyBlockApps {
                id
                primary {
                  content_1 {
                    html
                    text
                    richText
                  }
                  content_2 {
                    html
                    text
                    richText
                  }
                  eyebrow_1
                  eyebrow_2
                  heading
                  image_1 {
                    alt
                    url
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  }
                  image_2 {
                    alt
                    url
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  }
                  list_1_1
                  list_1_2
                  list_1_3
                  list_2_1
                  list_2_2
                  list_2_3
                  title_1
                  title_2
                }
                items {
                  list_1
                  list_2
                }
                slice_label
                slice_type
              }
              ... on PrismicPageDataBodyBlockList {
                id
                slice_label
                slice_type
                items {
                  heading
                  divider
                  content {
                    html
                    text
                  }
                  icon {
                    url
                    alt
                    gatsbyImageData
                  }
                  button_1_link {
                    url
                    link_type
                  }
                  button_1_label
                }
                primary {
                  eyebrow
                  heading
                  content {
                    richText
                  }
                  columns
                  cta_description {
                    richText
                  }
                  button_1_link {
                    url
                    link_type
                  }
                  button_label
                  button_2_link {
                    url
                    link_type
                  }
                  button_2_label
                  background
                  style
                }
              }
              ... on PrismicPageDataBodyBlockFullPhoto {
                id
                primary {
                  image {
                    url
                    alt
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  }
                }
                slice_label
                slice_type
              }
              ... on PrismicPageDataBodyBlockItems {
                id
                slice_label
                slice_type
                items {
                  content {
                    html
                    text
                    richText
                  }
                  heading
                  image {
                    url
                    alt
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  }
                }
                primary {
                  content {
                    html
                    text
                    richText
                  }
                  button_link
                  button_text
                  bottom_button_link
                  bottom_button_text
                  eyebrow
                  heading
                  heading_color
                  size
                }
              }
              ... on PrismicPageDataBodyBlockLargePhoto {
                id
                slice_label
                slice_type
                primary {
                  eyebrow
                  heading
                  list_1
                  list_2
                  list_3
                  image {
                    alt
                    url
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  }
                  content {
                    html
                    richText
                    text
                  }
                }
                items {
                  list_item
                }
              }
              ... on PrismicPageDataBodyBlockOverviewApps {
                id
                slice_label
                slice_type
                items {
                  content {
                    html
                    richText
                    text
                  }
                  heading
                  list {
                    html
                    richText
                    text
                  }
                }
                primary {
                  heading
                }
              }
              ... on PrismicPageDataBodyBlockPartners {
                id
                slice_label
                slice_type
                items {
                  logo {
                    alt
                    url
                    dimensions {
                      width
                      height
                    }
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  }
                }
                primary {
                  button_link_1
                  button_link_2
                  button_text_1
                  button_text_2
                  eyebrow
                  heading
                  content {
                    html
                    richText
                    text
                  }
                  image {
                    url
                  }
                }
              }
              ... on PrismicPageDataBodyHeroAbout {
                id
                slice_label
                slice_type
                primary {
                  content {
                    html
                    richText
                    text
                  }
                  content_1 {
                    html
                    richText
                    text
                  }
                  content_2 {
                    html
                    richText
                    text
                  }
                  eyebrow
                  heading
                  image {
                    alt
                    url
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  }
                  sub_heading
                  video {
                    url
                  }
                  video_title
                }
              }
              ... on PrismicPageDataBodyHeroCareers {
                id
                slice_label
                slice_type
                primary {
                  button_link
                  button_text
                  eyebrow
                  heading
                  content {
                    html
                    richText
                    text
                  }
                  image_2 {
                    alt
                    url
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  }
                  image_1 {
                    alt
                    url
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  }
                  image_3 {
                    alt
                    url
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  }
                  video {
                    url
                  }
                  video_title
                }
              }
              ... on PrismicPageDataBodyHeroContact {
                id
                slice_label
                slice_type
                primary {
                  email
                  heading
                  content {
                    html
                    richText
                    text
                  }
                  content_small {
                    html
                    richText
                    text
                  }
                  submit_redirect_url {
                    text
                  }
                  image {
                    alt
                    url
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  }
                }
              }
              ... on PrismicPageDataBodyTimelineBlock {
                id
                slice_label
                slice_type
                primary {
                  heading
                  eyebrow
                }
              }
              ... on PrismicPageDataBodyHeroHome {
                id
                slice_label
                slice_type
                primary {
                  heading
                  content {
                    html
                    richText
                    text
                  }
                  image {
                    alt
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)

                    url
                  }
                }
              }
              ... on PrismicPageDataBodyHeroSimple {
                id
                slice_label
                slice_type
                primary {
                  content {
                    html
                    richText
                    text
                  }
                  eyebrow
                  heading
                }
              }
              ... on PrismicPageDataBodyHeroOverview {
                id
                slice_label
                slice_type
                primary {
                  eyebrow
                  heading
                  content {
                    html
                    richText
                    text
                  }
                  content_2 {
                    html
                    richText
                    text
                  }
                  show_brochure
                  image1 {
                    url
                    alt
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  }
                }
              }
              ... on PrismicPageDataBodyLeadershipBlocks {
                id
                slice_label
                slice_type
                primary {
                  section_title
                  columns
                }
                items {
                  content {
                    html
                    text
                    richText
                  }
                  modal_content {
                    html
                    text
                    richText
                  }
                  heading
                  position
                  link {
                    url
                    raw
                  }
                  image {
                    alt
                    url
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  }
                }
              }
              ... on PrismicPageDataBodyPrivacyContent {
                id
                slice_label
                slice_type
                items {
                  content {
                    html
                    richText
                    text
                  }
                }
              }
              ... on PrismicPageDataBodyTestimonials {
                id
                slice_label
                slice_type
                items {
                  heading
                  position
                  quote
                  image {
                    alt
                    url
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  }
                }
              }
              ... on PrismicPageDataBodyBlockSection {
                id
                slice_label
                slice_type
                primary {
                  eyebrow
                  heading
                  heading_tag
                  description1
                  content_1 {
                    html
                    richText
                    text
                  }
                  content_2 {
                    html
                    richText
                    text
                  }
                }
              }
              ... on PrismicPageDataBodyContentCarousel {
                id
                slice_label
                slice_type
                primary {
                  eyebrow
                  mobile_screenshot
                  style
                }
                items {
                  image {
                    alt
                    url
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  }
                  title1
                  description1
                  button_1_link {
                    url
                    link_type
                  }
                  button_1_label
                  button_2_link {
                    url
                    link_type
                  }
                  button_2_label
                }
              }
              ... on PrismicPageDataBodyBlockPageNav {
                id
                slice_label
                slice_type
                primary {
                  menu {
                    document {
                      ... on PrismicMenu {
                        data {
                          items {
                            item_link {
                              link_type
                              url
                              document {
                                ... on PrismicMenu {
                                  data {
                                    items {
                                      item_link {
                                        link_type
                                        url
                                      }
                                      item_label
                                      item_description
                                      anchor_link
                                    }
                                  }
                                }
                              }
                            }
                            item_label
                            item_description
                            anchor_link
                          }
                        }
                      }
                    }
                    link_type
                  }
                  style
                  mobile_select_label
                }
              }
              ... on PrismicPageDataBodyBlockImage {
                id
                slice_label
                slice_type
                primary {
                  image1 {
                    alt
                    url
                    gatsbyImageData
                  }
                }
              }
              ... on PrismicPageDataBodyBlockContentColumns {
                id
                slice_label
                slice_type
                primary {
                  column_1 {
                    richText
                  }
                  column_2 {
                    richText
                  }
                  button_1_link {
                    url
                    link_type
                  }
                  button_1_label
                  button_2_link {
                    url
                    link_type
                  }
                  button_2_label
                }
              }
              ... on PrismicPageDataBodyBlockmatrix {
                id
                slice_label
                slice_type
                primary {
                  heading
                }
                items {
                  name
                  description_text {
                    richText
                  }
                  pingo_flex
                  transit_connect
                  pingo_journey
                  pingo_campus
                  call_center_booking
                  pre_booking
                  pingo_venues
                  pingo_access
                }
              }
              ... on PrismicPageDataBodyBlockcomparison {
                id
                slice_label
                slice_type
                primary {
                  heading
                  show_legend
                }
                items {
                  name
                  item_link {
                    url
                    link_type
                  }
                  on_demand_transit
                  paratransit
                  corporate
                  university
                }
              }
              ... on PrismicPageDataBodyEmbed {
                id
                slice_label
                slice_type
                primary {
                  heading
                  content {
										richText
                  }
                  embed_code
                }
              }
            }
            description
            keywords
            page
            slug
            title
            image {
              url
            }
          }
        }
      }
    }
  }
`

export default withPrismicPreview(Page)