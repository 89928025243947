import { createGlobalStyle } from 'styled-components'
import { media } from './media'

const GlobalStyles = createGlobalStyle`

:root {
  --border-radius: 4px;
  --color-text: #373736;
  --color-bg: #F3F2ED;
  --color-primary: #292524;
  --color-brown: #292524;
  --grey-2: RGBA(41,37,36,0.60);
  --color-code-bg: #fff4db;
  --color-code: #8a6534;
  --font-sans: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  --font-mono: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  --font-lg: 18px;
  --font-md: 16px;
  --font-sm: 14px;
  --font-sx: 12px;
  --line-height-loose: 1.75;
  --line-height-normal: 1.5;
  --line-height-dense: 1.1;
  --space-1: 4px;
  --space-2: 8px;
  --space-3: 16px;
  --space-4: 24px;
  --space-5: 32px;
  --space-6: 64px;
  --size-content: 54rem;
  --size-gutter: var(--space-5);
  --size-gap: var(--space-6);
}
html {
    // overflow-x: hidden;
    // overflow-y: scroll;
    scroll-behavior: smooth;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    font-family: var(--font-sans);
    font-size: var(--font-md);
    line-height: var(--line-height-normal);
    background: var(--color-bg);
}
body {
  position: relative;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: var(--color-text);
  font-weight: normal;
  margin: 0;
  word-wrap: break-word;
}
body, html {
  min-height: 100vh;
}
a {
  background-color: transparent;
  transition: all .15s linear;
  cursor: pointer;
  text-decoration: none;
}
a:active,
a:hover {
  outline-width: 0;
  text-decoration: none;
}
main {
    margin-top: 74px;
}
hr {
    opacity: 0.15;
    border-color: #292524;
}

.logo {
    height: 121px;
}

.color--primary {
  color: var(--color-primary);
}
.text-white {
    color: var(--color-bg) !important;
}
.bg--main {
  background-color: var(--color-bg);
}
.bg--primary {
  background-color: var(--color-primary);
}
.bg--brown {
    background-color: var(--color-brown);
}
.radius-32 {
  border-radius: 32px;
  transform: translateZ(0)
}
.radius-lb-32 {
    border-radius: 32px 0 0 32px;
    transform: translateZ(0)
}
.radius-rb-32 {
    border-radius: 0 32px 32px 0;
    transform: translateZ(0)
}
.radius-tl-32 {
    border-radius: 32px 32px 0 0;
    transform: translateZ(0)
}
.radius-56 {
    border-radius: 32px;
    transform: translateZ(0);
    @media ${media.mdUp} {
        border-radius: 56px;
    }
}

  .container {
    max-width: 1120px;
    width: 100%;
    margin: 0 auto;
    padding-left: 16px;
    padding-right: 16px;
    /*@media ${media.smUp} {
      padding-left: 32px;
      padding-right: 32px;
    }
    @media ${media.mdUp} {
      padding-left: 60px;
      padding-right: 60px;
    }
    @media ${media.xlUp} {
      padding-left: 0;
      padding-right: 0;
    }*/
  }
.wrapper {
    position: relative;
}
html.is-scroll-disabled-nav body {
    // height: 100vh;
    overflow: hidden;
    .nav--holder, .nav--holder--retail {
      &.nav-up {
        transform: translateY(0);
      }
    }
  }
  html.is-scroll-disabled body {
    // height: 100vh;
    overflow: hidden;
    main {
      position: relative;
      z-index: 40;
    }
    .btn--chat {
      opacity: 0;
    }
  }

  .nav--holder, .nav--holder--retail {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    opacity: 0;
    transition: all 0.3s linear;
    &.nav-up {
      transform: translateY(-100%);
      transition: all 0.3s linear;
    }
    &.nav-down {
      transform: translateY(0);
      transition: all 0.3s linear;
    }
    &.is-active {
      .nav--links {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
  .dash-list {
      li {
        padding-left: 1rem;
        position: relative;
        &::before {
            content: '–';
            position: absolute;
            top: 0rem;
            left: 0;
        }
      }
  }

  .bio {
      &.active {
          .bio--content--hide {
              max-height: 200px;
          }
      }
  }
  .bio--content {
      bottom: 0;
      cursor: pointer;
  }
  .bio--content--hide {
      max-height: 0;
      transition: all 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
  }
  .input-group {
      label {
          position: absolute;
          top: 3px;
          left: 20px;
          font-weight: 500;
          font-size: 10px;
          letter-spacing: 0.01em;
          color: var(--color-brown);
          transition: all .15s linear;
          opacity: 0;
      }
      input, textarea, select {
          padding: 15px 20px;
          width: 100%;
          background: none;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          transition: all .15s linear;
          ::-webkit-input-placeholder { /* Edge */
              color: rgba(41, 37, 36, 0.5);
          }
          :-ms-input-placeholder { /* Internet Explorer 10-11 */
              color: rgba(41, 37, 36, 0.5);
          }
          ::placeholder {
              color: rgba(41, 37, 36, 0.5);
          }
          &:focus {
              outline: 0;
              background: rgba(234, 233, 228, 1);
              border: 1px solid rgba(234, 233, 228, 1);
              + label {
                  opacity: 1;
              }
              ::-webkit-input-placeholder { /* Edge */
                  color: rgba(234, 233, 228, 1);
              }
              :-ms-input-placeholder { /* Internet Explorer 10-11 */
                  color: rgba(234, 233, 228, 1);
              }
              ::placeholder {
                  color: rgba(234, 233, 228, 1);
              }
          }
          &.error {
          border-color: #DA5B40;
          }
      }
      select {
        appearance: none;
      }
      textarea {
        height: 110px;
      }
  }

// Modal
html.is-scroll-disabled body {
    // height: 100vh;
    overflow: hidden;
    main {
        position: relative;
        z-index: 40;
    }
    .btn--chat {
        opacity: 0;
    }
}
.modal {
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(243,242,237,0.7);
    z-index: 40;
    // height: 100vh;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    transform: translateY(-100%);
    transition: all 0.4s cubic-bezier(0.4, 0, 1, 1); // Ease In
    &.is-active {
        transform: translateY(0);
        transition: all 0.5s cubic-bezier(0, 0, 0.2, 1); // Ease Out
    }
    &.modal--video {
        transform: translateY(0);
        opacity: 0;
        visibility: hidden;
        &.is-active {
            opacity: 1;
            visibility: visible;
        }
    }
}

.model--open {
    overflow: hidden;
    height: 100vh;
    .modal--bg, .video--bg {
        opacity: 1;
        visibility: visible;
    }
}
.modal--bg, .video--bg {
    position: fixed;
    z-index: 18;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(41, 37, 36, 0.05);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s linear;
    cursor: pointer;
    &.is-active {
        opacity: 1;
        visibility: visible;
    }
}
.video--bg {
    background: rgba(41, 37, 36, 0.5);
}
.video--holder video {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0;
    width: 80%;
    height: 80%;
}
#video--hero {
    z-index: 20;
}
.modal-inner {
  p + p {
    margin-top: 1rem;
  }
}

.html-content, .content--story {
    a:not(.btn--rounded) {
        border-bottom: 1px solid rgba(41, 37, 36, 0.25);
        .bg--primary & {
          border-bottom: 1px solid rgba(255, 255, 255, 0.25);
        }
        &:hover {
            border-bottom: 1px solid rgba(41, 37, 36, 0.1);
            .bg--primary & {
              border-bottom: 1px solid rgba(255, 255, 255, 0.5);
            }
        }
    }
    ul,
    ol {
      font-size: 1rem;
      padding-left: 1rem;
    }
    ul {
      list-style-type: disc;
    }
    ol {
      list-style-type: decimal;
    }
    p + p,
    p + ul,
    p + ol,
    ul + p,
    ul + ul,
    ul + ol,
    ol + p,
    ol + ol,
    ol + ul {
      margin-top: 1rem;
    }
}

.hover-zoom {
    overflow: hidden;
    .gatsby-image-wrapper {
        max-height: 450px;
    }
    &:hover {
      .hover-zoom-img {
        img[data-main-image] {
          transform: scale(1);
        }
      }
    }
  }
  .hover-zoom-img {
    img[data-main-image] {
      transform: scale(1.05);
      object-fit: cover;
      transition: all 0.5s linear;
    }

  }

.box-shadow {
    box-shadow: 0px 0 15px rgba(0, 0, 0, 0.05);
}
.grayscale img {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
 }

 .BlockList.bg--primary {
  + .BlockTestimonials {
    margin-top: 0;
  }
 }

 .BlockImage {
  + .BlockContentColumn {
    margin-top: 2rem;
  }
 }

 .BlockPageNav {
  + .BlockHeroOverview {
    > div {
      @media ${media.mdUp} {
        padding-top: 0;
      }
    }
  }
 }

 .BlockHeroCareers3 {
  + .BlockList {
    margin-top: 1rem;

    @media ${media.mdUp} {
      margin-top: 6rem;
    }
  }
 }

 .BlockLargePhoto {
  + .BlockPartners {
    margin-top: 0;
  }
}


.display_archive {
  .campaign {
    border-bottom: 1px solid #ccc;
    color: #5d5c5b;
    display: flex;
    flex-direction: column;
    padding: 20px 0 20px 0;
    position: relative;

    a {
      font-size: 18px;
      color: black;
      padding-right: 50px;
      position: relative;

      &:after {
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzYiIGhlaWdodD0iMzciIHZpZXdCb3g9IjAgMCAzNiAzNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMzYiIHk9IjM2LjQ0NTMiIHdpZHRoPSIzNiIgaGVpZ2h0PSIzNiIgcng9IjE4IiB0cmFuc2Zvcm09InJvdGF0ZSgtMTgwIDM2IDM2LjQ0NTMpIiBmaWxsPSJyZ2JhKDU1LDU1LDU0LDAuMDUpIi8+CjxnIG9wYWNpdHk9IjAuOTgiPgo8ZyBjbGlwLXBhdGg9InVybCgjY2xpcDBfMF8zMjM4KSI+CjxwYXRoIGQ9Ik0xMC41IDE5LjM4MjhMMjEuOTA5NCAxOS4zODI4TDE2LjY2ODcgMjQuNjIzNEwxOCAyNS45NDUzTDI1LjUgMTguNDQ1M0wxOCAxMC45NDUzTDE2LjY3ODEgMTIuMjY3MkwyMS45MDk0IDE3LjUwNzhMMTAuNSAxNy41MDc4TDEwLjUgMTkuMzgyOFoiIGZpbGw9IiMzNzM3MzYiLz4KPC9nPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzBfMzIzOCI+CjxyZWN0IHdpZHRoPSIyMi41IiBoZWlnaHQ9IjIyLjUiIGZpbGw9InJnYmEoNTUsNTUsNTQsMC4wNSkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDI5LjI1IDI5LjY5NTMpIHJvdGF0ZSgtMTgwKSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=);
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        width: 36px;
        height: 37px;
        transition: .2s ease;
      }

      &:hover {
        &:after {
          right: -5px;
        }
      }
    }

    
  }
}
`

export default GlobalStyles
