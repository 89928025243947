import React, { Component } from 'react'
import styled from 'styled-components'
import { gsap } from 'gsap'
import { Link } from 'gatsby'
// import { GatsbyImage } from 'gatsby-plugin-image'
// import home0101 from '../images/home-01-01.jpg'
// import home0102 from '../images/home-01-02.jpg'
// import home0103 from '../images/home-01-03.jpg'

const Section = styled.section`
  &.dark {
    background-color: #ebeae4;
  }
`

class BlockButton extends Component {
  componentDidMount() {
    const fadeUp = document.querySelectorAll('#block-home-01 .fade-up')

    fadeUp.forEach((element, index) => {
      gsap.from(element, {
        scrollTrigger: {
          trigger: element,
          start: 'top 90%',
        },
        duration: 0.5,
        opacity: '0',
        y: '20%',
      })
    })
  }

  render() {
    const { primary } = this.props.slice

    return (
      <Section
        id={`block-home-${this.props.index}`}
        className={`relative  ${
          this.props.index === 1
            ? 'dark pt-10 pb-10 md:pt-16 md:pb-16'
            : 'pt-10 md:pt-24'
        }`}
      >
        <div className="container">
          <div className="grid grid-cols-4 md:grid-cols-12 mb-8 md:mb-10">
            <div className="col-span-4 md:col-span-3 lg:col-span-2">
              <span className="eyebrow-num fade-up"></span>
            </div>
            <div className="col-span-4 md:col-span-4 md:col-start-6 lg:col-span-6 lg:col-start-6">
              <p className="text-lg md:text-3xl fade-up mb-10"></p>
              {primary.button_text ? (
                <Link to={primary.button_link} className="btn--rounded">
                  {primary.button_text}
                </Link>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </Section>
    )
  }
}
export default BlockButton
