import { Link } from 'gatsby';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import arrow from '../images/circle-arrow-dark.svg';
import { media } from '../styles/media';

const Nav = styled.ul`
  transition: all 0.3s ease-in;

  &.is-stuck {
  }

  > li {
    display: inherit;
    padding: 0 10px 0;
    @media ${media.xlUp} {
      padding: 0 30px 0 0;
    }

    &:hover {
      .btn--text {
        &::before {
          animation: 0.25s linear 0s line-in forwards;
        }
      }
    }
  }
  .btn--text {
    padding: 30px 0;

    &:before {
      bottom: 28px;
    }
  }
  .sub-menu {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-20px);

    &[aria-hidden='false'] {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      transition: 0.6s cubic-bezier(0.83, 0, 0.17, 1);
    }
  }
  .plink {
    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 2px;
      background: var(--color-primary);
      transition: all 0.15s linear;
      transform: translateX(calc(-100% - 0.1rem));
      animation: 0.25s linear 0s line-out forwards;
    }
    &:hover,
    &:focus,
    &.is-active {
      color: var(--color-primary);
      &::before {
        animation: 0.25s linear 0s line-in forwards;
      }
    }
  }
  .slink {
    &:hover,
    &:focus {
      img {
        transform: translateX(5px);
        transition: 0.4s cubic-bezier(0.83, 0, 0.17, 1);
      }
    }
  }
  .nav-img {
    opacity: 0;
    max-height: 0;
    width: 130%;
    max-width: none;
    @media ${media.lgUp} {
      width: 150%;
    }

    &.is-active {
      transition: 0.4s cubic-bezier(0.83, 0, 0.17, 1);
      opacity: 1;
      max-height: none;
    }
  }
`;

export default function NavLinks({ nav, mobile }) {
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(0);
  const [activeImg, setActiveImg] = useState(0);
  const menuRef = useRef();

  const showMenuClick = (index) => {
    // if( activeMenu === index) {
    //   setActiveMenu(null)
    //   setActiveImg(0)
    // } else {
    //   setActiveMenu(index)
    // }
  };
  const showMenuHover = (index) => {
    setActiveMenu(index);
  };
  const closeMenu = () => {
    setActiveMenu(null);
    setActiveImg(0);
  };

  const keyMenu = (e) => {
    const { keyCode } = e;
    const index = parseInt(e.target.getAttribute('data-index'));

    switch (keyCode) {
      // enter key
      case 13:
      case 32:
        setActiveMenu(index);
        setActiveSubMenu(0);
        break;
      // esc key
      case 27:
        setActiveMenu(null);
        setActiveSubMenu(0);
        setActiveImg(0);
        break;
    }
  };

  const keySubMenu = (e) => {
    const { keyCode } = e;
    const index = parseInt(e.target.getAttribute('data-index'));

    switch (keyCode) {
      // enter key
      case 13:
      case 32:
        setActiveSubMenu(index);
        e.preventDefault();
        break;
      // esc key
      case 27:
        setActiveMenu(null);
        setActiveSubMenu(0);
        setActiveImg(0);
        break;
    }
  };

  const showImage = (e) => {
    const link = e.target;
    const index = parseInt(link.getAttribute('data-img'));
    setActiveImg(index);
  };

  // click outside to close nav
  const closeOpenMenus = useCallback(
    (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setActiveMenu(null);
      }
    },
    [activeMenu]
  );

  // const scrollCloseMenus = useCallback((e) => {
  //   console.log(`menuOpened`, menuOpened)
  //   if( menuOpened != 1) {
  //     setActiveMenu(null)
  //   }
  // }, [menuOpened])

  useEffect(() => {
    document.addEventListener('mousedown', closeOpenMenus);
    // document.addEventListener("scroll", scrollCloseMenus)
  }, [closeOpenMenus]);

  return (
    <Nav
      className={`nav--links ${
        mobile ? 'flex md:hidden justify-center flex-wrap' : ''
      } ${
        !mobile
          ? 'hidden md:flex md:justify-center lg:justify-start col-span-4'
          : ''
      }`}
      ref={menuRef}
    >
      {nav.map((item, index) => (
        <React.Fragment key={`tItem-${index}`}>
          {item.menu.id === null && (
            <li>
              <Link to={item.url} className="btn--text color--grey-5">
                {item.title}
              </Link>
            </li>
          )}
          {item.menu.id != null && (
            <li
              onMouseEnter={() => showMenuHover(index)}
              onMouseLeave={closeMenu}
            >
              <button
                to={item.url}
                className="btn--text color--grey-5"
                aria-haspopup="true"
                aria-expanded={activeMenu === index ? 'true' : 'false'}
                onClick={() => showMenuClick(index)}
                onKeyDown={keyMenu}
                data-index={index}
              >
                {item.title}
              </button>
              <div
                id={`sub-menu-${index}`}
                className="sub-menu absolute top-[74px] z-20 left-0 w-full bg-ltrgray py-16"
                role="group"
                aria-labelledby={`menu-title-${index}`}
                aria-hidden={activeMenu === index ? 'false' : 'true'}
              >
                <div className="container !mx-auto !px-4 grid grid-cols-12 gap-4">
                  <ul className="col-span-4">
                    <li className="mb-6">
                      <span
                        id={`menu-title-${index}`}
                        className="eyebrow col-span-2 border-t border-ltgray pt-2"
                      >
                        {item.title}
                      </span>
                    </li>
                    {item.menu.document.data.items.map((item, idx) => (
                      <li
                        className="my-6"
                        key={`mItem-${idx}`}
                        onMouseEnter={() => setActiveSubMenu(idx)}
                      >
                        {item.item_link.document &&
                          Object.keys(item.item_link.document).length === 0 && (
                            <Link
                              to={
                                item.anchor_link
                                  ? item.anchor_link
                                  : item.item_link.url
                              }
                              className={`plink relative overflow-hidden inline-block text-3xl leading-none hover:opacity-100 ${
                                activeSubMenu === idx
                                  ? 'is-active opacity-100'
                                  : 'opacity-50'
                              }`}
                              data-index={idx}
                              onKeyDown={closeMenu}
                              onClick={closeMenu}
                            >
                              {item.item_label}
                            </Link>
                          )}
                        {item.item_link.document &&
                          Object.keys(item.item_link.document).length != 0 && (
                            <Link
                              to={
                                item.item_link.document.data.items[0]
                                  .anchor_link
                                  ? item.item_link.document.data.items[0]
                                      .anchor_link
                                  : item.item_link.document.data.items[0]
                                      .item_link.url
                              }
                              className={`plink relative overflow-hidden inline-block text-3xl leading-none hover:opacity-100 ${
                                activeSubMenu === idx
                                  ? 'is-active opacity-100'
                                  : 'opacity-50'
                              }`}
                              onKeyDown={keySubMenu}
                              onClick={closeMenu}
                              aria-haspopup="true"
                              aria-expanded={
                                activeSubMenu === idx ? 'true' : 'false'
                              }
                              data-index={idx}
                            >
                              {item.item_label}
                            </Link>
                          )}
                      </li>
                    ))}
                  </ul>
                  {item.menu.document.data.items.map((pItem, idx) => (
                    <React.Fragment key={`wrap-${idx}`}>
                      {pItem.item_link.document &&
                        Object.keys(pItem.item_link.document).length != 0 && (
                          <React.Fragment key={`pItem-${idx}`}>
                            {/* Menu with images */}
                            {pItem.item_link.document.data.has_images ===
                              true && (
                              <div
                                className={`col-span-8 grid grid-cols-8 gap-4 items-center ${
                                  activeSubMenu === idx ? '' : 'hidden'
                                }`}
                                aria-hidden={
                                  activeSubMenu === idx ? 'false' : 'true'
                                }
                              >
                                <ul className="col-span-4 xl:col-span-3">
                                  {pItem.item_link.document.data.items.map(
                                    (sItem, idx) => (
                                      <li key={`sItem-${idx}`}>
                                        <Link
                                          to={
                                            sItem.anchor_link
                                              ? sItem.anchor_link
                                              : sItem.item_link.url
                                          }
                                          className="slink flex items-center justify-between lh-solid py-4 border-b border-gray"
                                          data-img={idx}
                                          data-src={sItem.item_image.url}
                                          onMouseEnter={showImage}
                                          onClick={closeMenu}
                                        >
                                          <p className="pointer-events-none flex flex-col pr-4">
                                            <strong className="text-lg font-medium">
                                              {sItem.item_label}
                                            </strong>
                                            <span className="opacity-75">
                                              {sItem.item_description}
                                            </span>
                                          </p>
                                          <img
                                            className="pointer-events-none"
                                            src={arrow}
                                            alt=""
                                          />
                                        </Link>
                                      </li>
                                    )
                                  )}
                                </ul>
                                <div className="col-span-4 pl-4 lg:pl-8 xl:pl-0 xl:col-start-5 xl:col-end-9">
                                  {pItem.item_link.document.data.items.map(
                                    (sItem, idx) => (
                                      <img
                                        id={`img-${index}-${idx}`}
                                        className={`nav-img ${
                                          idx === activeImg ? 'is-active' : ''
                                        }`}
                                        src={
                                          idx === activeImg
                                            ? sItem.item_image.url
                                            : ''
                                        }
                                        data-src={sItem.item_image.url}
                                        key={`img-${idx}`}
                                        alt=""
                                        width="645"
                                        height="500"
                                        loading="lazy"
                                      />
                                    )
                                  )}
                                </div>
                              </div>
                            )}
                            {/* Reg Menu */}
                            {pItem.item_link.document.data.has_images !=
                              true && (
                              <div
                                className={`col-span-8 ${
                                  activeSubMenu === idx ? '' : 'hidden'
                                }`}
                                aria-hidden={
                                  activeSubMenu === idx ? 'false' : 'true'
                                }
                              >
                                <ul className="grid grid-cols-2 gap-4 lg:gap-y-4 lg:gap-x-10 xl:gap-x-20 !items-end">
                                  {pItem.item_link.document.data.items.map(
                                    (sItem, idx) => (
                                      <li key={`sItem-${idx}`}>
                                        <Link
                                          to={
                                            sItem.anchor_link
                                              ? sItem.anchor_link
                                              : sItem.item_link.url
                                          }
                                          className="slink flex items-center justify-between lh-solid py-4 border-b border-gray"
                                          data-img={`img-${index}-${idx}`}
                                          onClick={closeMenu}
                                        >
                                          <p className="flex flex-col pr-4">
                                            <strong className="text-lg font-medium">
                                              {sItem.item_label}
                                            </strong>
                                            <span className="opacity-75">
                                              {sItem.item_description}
                                            </span>
                                          </p>
                                          <img
                                            className="pointer-events-none"
                                            src={arrow}
                                            alt=""
                                          />
                                        </Link>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            )}
                          </React.Fragment>
                        )}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </li>
          )}
        </React.Fragment>
      ))}
    </Nav>
  );
}
