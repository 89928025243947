import React, { Component } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import { gsap } from 'gsap'
import logo from '../images/the-routing-company.svg'
// import brochure from '../images/TRC-Brochure-Oct-2022.pdf'

class BlockHeroOverview extends Component {
  constructor(props) {
    super(props)
    this.state = { active: false }
  }

  componentDidMount() {
    const fadeElements = gsap.timeline()
    fadeElements.to('#block-hero-overview', {
      opacity: 1,
      delay: 0.25,
    })
    fadeElements.from('#block-hero-overview .fade-up', {
      duration: 0.7,
      opacity: '0',
      y: '20%',
      stagger: {
        amount: '0.5',
      },
    })
  }

  render() {
    const { primary, items } = this.props.slice
    const { global } = this.props

    return (
      <header id="block-hero-overview" className="[ BlockHeroOverview ] container">
        {primary.heading &&
          <div className="grid grid-cols-12 pt-10 md:pt-16">
            <div className="col-span-12 md:col-span-10">
              <h1 className="h1 md:mb-10 color--primary fade-up">
                {primary.heading}
              </h1>
            </div>
            <div className="col-span-2 hidden md:block">
              <div className="flex justify-end">
                <img
                  src={logo}
                  alt="The Routing Company"
                  className="fade-up logo"
                />
              </div>
            </div>
          </div>
        }
        <div className="grid grid-cols-12 mt-8 md:mt-10 md:mb-10">
          <div className="col-span-12 md:col-span-2">
            <span className="eyebrow-name fade-up">{primary.eyebrow}</span>
          </div>
        </div>
        <div className="grid grid-cols-12 mb-12">
          <div className="html-content col-span-12 md:col-span-5 mb-10 md:mb-0">
            <PrismicRichText
              field={primary.content.richText}
              components={{
                paragraph: ({ children }) => <p className='text-lg md:text-3xl fade-up'>{children}</p>,
              }}
            />
            {primary.show_brochure && global?.brochure_link?.url &&
              <div className="fade-up">
                <a
                  href={global.brochure_link.url}
                  target="_blank"
                  className="btn--rounded no-underline"
                  rel="noreferrer"
                >
                  Download our product brochure
                </a>
              </div>
            }
          </div>
          <div className="html-content col-span-12 md:col-span-5 md:col-start-7">
            <PrismicRichText
              field={primary.content_2.richText}
              components={{
                paragraph: ({ children }) => <p className='md:text-lg fade-up'>{children}</p>,
              }}
            />
          </div>
          { primary.image1?.url &&
            <div className="col-span-12 mt-10 md:mt-20">
              <GatsbyImage
                image={primary.image1.gatsbyImageData}
                alt={primary.image1.alt||''}
                className="block radius-32 [ w-full h-full object-cover ]"
              />
            </div>
          }
        </div>
      </header>
    )
  }
}
export default BlockHeroOverview
