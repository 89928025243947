import { createGlobalStyle } from 'styled-components'
import { media } from './media'

import maison5001 from '../fonts/MaisonNeue-Medium.woff'
import maison5002 from '../fonts/MaisonNeue-Medium.woff2'
import maison6001 from '../fonts/MaisonNeue-DemiBold.woff'
import maison6002 from '../fonts/MaisonNeue-DemiBold.woff2'
import maison1 from '../fonts/MaisonNeue-Book.woff'
import maison2 from '../fonts/MaisonNeue-Book.woff2'
import general1 from '../fonts/GeneralGrotesqueMono-Book.woff'
import general2 from '../fonts/GeneralGrotesqueMono-Book.woff2'

const Typography = createGlobalStyle`

@font-face {
    font-family: 'Maison Neue';
    src: url(${maison5002}) format('woff2'),
        url(${maison5001}) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue';
    src: url(${maison6002}) format('woff2'),
        url(${maison6001}) format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue';
    src: url(${maison2}) format('woff2'),
        url(${maison1}) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'General Grotesque Mono Book';
    src: url(${general2}) format('woff2'),
        url(${general1}) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

body {
    font-family: 'Maison Neue', 'Open Sans', 'Helvetica Neue', sans-serif;
}
.h0 {
    font-weight: 500;
    font-size: 62px;
    line-height: 56px;
    letter-spacing: -0.02em;
    @media ${media.mdUp} {
        font-size: 80px;
        line-height: .92;
    }
    @media ${media.lgUp} {
        font-size: 120px;
    }
}
.h1 {
    font-weight: 500;
    font-size: 62px;
    line-height: 56px;
    letter-spacing: -0.02em;
    @media ${media.mdUp} {
        font-size: 80px;
        line-height: .92;
    }
}
.h2 {
    font-weight: 500;
    font-size: 36px;
    line-height: 100%;
    letter-spacing: -0.02em;
    @media ${media.mdUp} {
        font-size: 48px;
        line-height: 48px;
    }
}
.h3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 124%;
    @media ${media.mdUp} {
        font-size: 44px;
        line-height: 44px;
        letter-spacing: -0.02em;
    }
}
p {
    font-size: 16px;
    line-height: 24px;
}
blockquote {
    position: relative;
    font-size: 26px;
    line-height: 32px;
    @media ${media.mdUp} {
        font-size: 38px;
        line-height: 50px;
        padding: 0 0 0 20px;
    }
}

.eyebrow {
    font-family: 'General Grotesque Mono Book';
    font-weight: 500;
    font-size: 10px;
    line-height: 110%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    opacity: 0.5;
    @media ${media.mdUp} {
        font-size: 12px;
        line-height: 14px;
    }
}
.eyebrow-name {
    display: inline-block;
    font-family: 'General Grotesque Mono Book';
    font-weight: bold;
    font-size: 10px;
    margin-bottom: 32px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    background-color: rgba(55, 55, 54, 0.05);
    //color: var(--color-bg);
    border-radius: 25px;
    padding: 3px 10px 5px 11px;
    text-align: center;
    @media ${media.mdUp} {
        display: block;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        padding: 8px 0 0 0;
        border-top: 1px solid rgba(55, 55, 54, 0.2);
        color: rgba(55, 55, 54, 0.5);
        background: none;
        border-radius: 0;
        width: 100%;
        text-align: left;
        margin-bottom: 0;
    }
    &.white {
        background: #F3F2ED;
        color: rgba(55, 55, 54, 0.5);
        @media ${media.mdUp} {
            border-top: 1px solid rgba(255,255,255, 0.2);
            color: rgba(255,255,255,0.5);
            background: none;
        }
    }
}
.eyebrow-num {
    display: inline-block;
    font-family: 'General Grotesque Mono Book';
    font-weight: bold;
    font-size: 10px;
    margin-bottom: 32px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    width: 25px;
    background-color: var(--color-brown);
    color: var(--color-bg);
    border-radius: 25px;
    padding: 3px 10px 5px 11px;
    text-align: center;
    @media ${media.mdUp} {
        display: block;
        font-size: 14px;
        font-weight: 500;
        line-height: 120%;
        padding: 8px 0 0;
        border-top: 1px solid rgba(55, 55, 54, 0.2);
        color: rgba(55, 55, 54, 0.5);
        background: none;
        border-radius: 0;
        width: 100%;
        text-align: left;
        margin-bottom: 0;
    }
    &.white {
        border-top: 1px solid rgba(255,255,255, 0.2);
        color: rgba(255,255,255,0.5);
    }
}
.ul--lines {
    font-size: 18px;
    line-height: 24px;
    border-bottom: 1px solid rgba(55, 55, 54, 0.15);
    li {
        padding: 7px 0;
        border-top: 1px solid rgba(55, 55, 54, 0.15);
    }
}

.pill {
    display: inline-block;
    padding: 5px 15px 6px;
    font-family: 'General Grotesque Mono Book';
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 0.01em;
    border-radius: 100px;
    background: rgba(55, 55, 54, 0.08);
    color: #292524;
    &.pill--purple {
        color: #1530BD;
        background: rgba(21, 48, 189, 0.08);
    }
    &.pill--yellow {
        color: #D9A105;
        background: rgba(217, 161, 5, 0.08);
    }
}
.caption {
    font-weight: 500;
    font-size: 8px;
    line-height: 140%;
    letter-spacing: 0.01em;
    @media ${media.mdUp} {
        font-size: 12px;
        line-height: 100%;
    }
}
.content--story {
    p {
        margin: 20px 0;
    }
    h2 {
        font-weight: 500;
        font-size: 26px;
        line-height: 140%;
    }
    h3 {
        max-width: 450px;
        margin: 30px 0;
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
    }
    h4 {
        margin: 15px 0;
        font-size: 18px;
        font-weight: 500;
        line-height: 140%;
    }
    .caption {
        margin-bottom: 60px;
        width: 100%;
        font-size: 10px;
        line-height: 160%;
        letter-spacing: 0.01em;
        opacity: 0.7;
    }
    hr {
        margin: 30px 0;
        border-color: rgba(0, 0, 0, 0.1);
    }
    li {
        margin: 1.5rem 0;
    }
    ol,
    ul {
        margin: 1.5rem;
    }
    ol {
        list-style: decimal;
        ol {
          list-style: upper-alpha;
        }
        ul ul {
          list-style: circle;
        }
    }
    ul {
        list-style-type: disc;
    }
}
.h-divide {
    position: relative;
    margin-bottom: 20px;
    @media ${media.mdUp} {
        margin-bottom: 40px;
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        top: -20px;
        opacity: 0.2;
        border-bottom: 1px solid #292524;
        @media ${media.mdUp} {
            bottom: -18px;
            top: auto;
            width: 50px;
        }
    }

    &--lt {
        &:after {
            border-bottom: 1px solid rgba(255,255,255,0.2);
            opacity: 1;
        }
    }
}
.content {
    p {
        margin-bottom: 20px;
        @media ${media.mdUp} {
            font-size: 20px;
            line-height: 30px;
        }
    }
}
`

export default Typography
