/**
 * Todo
 * - add debounce to search-box.js?
 */
import algoliasearch from "algoliasearch/lite"
import { createRef, default as React, useState, useMemo } from "react"
import { ScrollTo, RefinementList, InstantSearch,Pagination } from "react-instantsearch-dom"
import SearchBox from "./search-box"
import SearchResult from "./search-result"

export default function Search({ indices }) {
  const [query, setQuery] = useState()
  const [hasFocus, setFocus] = useState(false)
  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.ALGOLIA_APP_ID || 'YSIOCWNN8A',
        process.env.ALGOLIA_SEARCH_KEY || 'a0f5bd6aac6b54ab46bda8376ca276c6'
      ),
    []
  )

  return (
    <div className="container my-8 md:mt-24">
      <InstantSearch
        searchClient={searchClient}
        indexName={indices[0].name}
        onSearchStateChange={({ query }) => setQuery(query)}
      >
        <ScrollTo>
          <SearchBox
            className="[SearchBox] mb-8"
            onFocus={() => setFocus(true)}
            hasFocus={hasFocus}
          />
        </ScrollTo>
        {/* Scroll here when results change including when pagination is clicked */}
        {/* These facets are configured in the Alpolia dashboard: */}
        {/* <ScrollTo>
          <div className="[facets] capitalize mb-24 text-center">
            <RefinementList
              className="[RefinementList][doctype] inline-block"
              attribute="type"
              transformItems={items =>
                items.map(item => ({
                  ...item,
                  label: item.label === 'blog' ? 'Newsroom' : item.label,
                }))
              }
            />
            <RefinementList
              className="[RefinementList][blogtags] inline-block"
              attribute="tag"
            />
          </div>
        </ScrollTo> */}
        {/* <pre>{JSON.stringify(query)}</pre> */}
        <SearchResult
          className={query && query.length ? 'block' : 'hidden'}
          // show={query && query.length > 3 && hasFocus}
          indices={indices}
        />
        <Pagination
          className={query && query.length ? 'block' : 'hidden'}
          // Optional parameters
          defaultRefinement={10}
          showFirst={true}
          showPrevious={true}
          showNext={true}
          showLast={true}
          padding={10}
          totalPages={10}
          // translations={object}
        />
      </InstantSearch>
    </div>
  )
}