import React, { Component } from 'react'
import styled from 'styled-components'
import { gsap } from 'gsap'
import logo from '../images/the-routing-company.svg'
// import hero from '../images/home--hero.jpg'
import { media } from '../styles/media'

const Hero = styled.header`
  opacity: 0;
  @media ${media.lgUp} {
    height: 100vh;
  }
  .bg--hero {
    border-radius: 1000px 1000px 0 0;
    overflow: hidden;
  }
  .bg--photo {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: cover;
  }
  .scroll--indicator {
    display: none; // HIDING SCROLL INDICATOR until we need it again
    transition: all 1s ease-in;
    &.hide {
      opacity: 0;
    }
  }
`

class BlockHero extends Component {
  componentDidMount() {
    let didScroll = false
    const scrollIndicator = document.querySelector('.scroll--indicator')
    function checkScroll() {
      didScroll = true
    }

    function hasScrolled() {
      const st = document.documentElement.scrollTop

      if (st > 0) {
        scrollIndicator.classList.add('hide')
      } else {
        scrollIndicator.classList.remove('hide')
      }
    }

    setInterval(() => {
      if (didScroll) {
        hasScrolled()
        didScroll = false
      }
    }, 250)

    window.addEventListener('scroll', checkScroll)

    const fadeElements = gsap.timeline()
    fadeElements.to('#block-hero', {
      opacity: 1,
      delay: 0.25,
    })
    fadeElements.from('#block-hero .fade-up', {
      duration: 0.7,
      opacity: '0',
      y: '20%',
      stagger: {
        amount: '0.5',
      },
    })

    const bg = document.querySelector('.bg--photo')

    const bgTL = gsap.timeline({
      scrollTrigger: {
        trigger: '#block-hero',
        scrub: 1,
        start: 'top',
      },
    })

    bgTL.fromTo(bg, { scale: 1.1 }, { scale: 1, force3D: true }, 0)
  }

  render() {
    const { primary, items } = this.props.slice
    const bgStyle = {
      backgroundImage: `url(${primary.image.url || ''})`,
    }
    return (
      <Hero id="block-hero" className="container relative">
        <div className="hidden md:flex justify-end pt-10">
          <img src={logo} alt="The Routing Company" className="fade-up logo" />
        </div>
        <div className="relative">
          <div className="grid grid-cols-12 relative z-10 pt-10 md:pt-0">
            <div className="col-span-12 lg:col-span-9 xl:col-span-8">
              <h1 className="h1 lg:!text-5xl mb-10 color--primary fade-up">
                {primary.heading}
              </h1>
            </div>
          </div>
          <div className="grid grid-cols-12 relative z-10">
            <div className="col-span-12 md:col-span-5 md:pr-20">
              <p className="text-lg fade-up">{primary.content.text}</p>
              <div className="pt-10 md:pt-16 md:pb-16 fade-up">
                <div className="scroll--indicator btn--circle">
                  <svg
                    width="13"
                    height="13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="m11.87 6.08-1.326-1.326-5.303 5.304 1.326 1.325L11.87 6.08ZM6.567 8.732 2.589 4.754 1.264 6.08l3.977 3.978 1.326-1.326Z"
                      fill="#F3F2ED"
                    />
                    <mask
                      id="a"
                      maskUnits="userSpaceOnUse"
                      x="1"
                      y="4"
                      width="11"
                      height="8"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="m11.87 6.08-1.326-1.326-5.303 5.304 1.326 1.325L11.87 6.08ZM6.567 8.732 2.589 4.754 1.264 6.08l3.977 3.978 1.326-1.326Z"
                        fill="#fff"
                      />
                    </mask>
                    <g mask="url(#a)">
                      <path
                        fill="#373736"
                        d="M-7.563-6.563h28.125v28.125H-7.563z"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg--hero md:absolute bottom-0 right-0 z-0 w-full md:w-6/12 h-80 md:h-4/5 fade-up">
          <div style={bgStyle} className="bg--photo relative">
            {/* <div style={bgStyle} className="bg--photo absolute top-0 left-0 right-0 bottom-0"></div> */}
          </div>
        </div>
      </Hero>
    )
  }
}
export default BlockHero
