/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './header'
import './layout.css'
import Buttons from '../styles/Buttons'
import GlobalStyles from '../styles/GlobalStyles'
import Typography from '../styles/Typography'
import Footer from './Footer'
import CookieConsent from 'react-cookie-consent';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteGlobalQuery {
      global: allPrismicGlobalVariables {
        edges {
          node {
            data {
              contact_content
              contact_email
              contact_heading
              contact_phone
              nav_button
              nav_button_url
              dropdown_nav {
                title
                url
                menu {
                  id
                  document {
                    ...on PrismicMenu {
                      id
                      url
                      data {
                        has_images
                        items {
                          item_link {
                            url
                            link_type
                            document {
                              ... on PrismicMenu {
                                id
                                data {
                                  has_images
                                  items {
                                    item_link {
                                      url
                                      link_type
                                    }
                                    item_image {
                                      alt
                                      url
                                      gatsbyImageData
                                    }
                                    item_label
                                    item_description
                                    anchor_link
                                  }
                                }
                              }
                            }
                          }
                          item_image {
                            alt
                            url
                            gatsbyImageData
                          }
                          item_label
                          item_description
                          anchor_link
                        }
                      }
                    }
                  }
                }
              }
              footer_nav_company {
                title
                url
              }
              footer_nav_contact {
                title
                url
              }
              top_nav {
                title
                url
                menu {
                  id
                  document {
                    ...on PrismicMenu {
                      id
                      url
                      data {
                        has_images
                        items {
                          item_link {
                            url
                            link_type
                            document {
                              ... on PrismicMenu {
                                id
                                data {
                                  has_images
                                  items {
                                    item_link {
                                      url
                                      link_type
                                    }
                                    item_image {
                                      alt
                                      url
                                      gatsbyImageData
                                    }
                                    item_label
                                    item_description
                                    anchor_link
                                  }
                                }
                              }
                            }
                          }
                          item_image {
                            alt
                            url
                            gatsbyImageData
                          }
                          item_label
                          item_description
                          anchor_link
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const global = data.global.edges[0].node.data

  return (
    <>
      <GlobalStyles />
      <Buttons />
      <Typography />
      <div className="wrapper">
        <Header global={global} />
        <main>{children}</main>
        <Footer global={global} />
      </div>
      <CookieConsent
        containerClasses="[CookieConsent] w-full sticky bottom-0 z-[9999] left-0 bg--primary text-offwhite p-6 flex flex-wrap gap-4 items-center justify-between"
        disableStyles="true"
        location="none"
        buttonText="Accept"
        buttonClasses="rounded-md bg-offwhite text-black px-4 py-1"
        enableDeclineButton
        declineButtonText="Decline"
        declineButtonClasses="rounded-md border border-offwhite px-4 py-1"
        buttonWrapperClasses="flex gap-4 grid-cols-2"
        cookieName="gatsby-gdpr-google-analytics"
        onAccept={()=>location.reload()}
        onDecline={()=>location.reload()}
      >
        <p>This website uses cookies and analytics to collect data. We use it to understand how you use our website so we can improve design and functionality. Please choose your cookie preference:</p>
      </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
