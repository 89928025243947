import React, { Component } from 'react'
import styled from 'styled-components'
import { gsap } from 'gsap'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
// import home0201 from '../images/home-02-01.jpg'
// import home0202 from '../images/home-02-02.jpg'

const Section = styled.section`
  background-color: #ebeae4;
`

class BlockLargePhoto extends Component {
  componentDidMount() {
    const fadeUp = document.querySelectorAll('#block-large-photo .fade-up')

    fadeUp.forEach((element, index) => {
      gsap.from(element, {
        scrollTrigger: {
          trigger: element,
          start: 'top 90%',
        },
        duration: 0.5,
        opacity: '0',
        y: '20%',
      })
    })
  }

  render() {
    const { primary, items } = this.props.slice

    return (
      <Section id="block-large-photo" className="[ BlockLargePhoto ] pt-10 md:pt-24">
        <div className="container relative">
          <div className="grid grid-cols-12 md:mb-16">
            <div className="col-span-12 md:col-span-2">
              <span className="eyebrow-name fade-up">{primary.eyebrow}</span>
            </div>
          </div>
          <div className="grid grid-cols-12">
            <div className="col-span-12 md:col-span-4">
              <h3 className="h2 mb-6 md:mb-0 opacity-50 fade-up">
                {primary.heading}
              </h3>
            </div>
            <div className="col-span-12 md:col-span-5 md:col-start-6">
              <p className="text-lg mb-6">{primary.content.text}</p>
              <ul className="dash-list">
                {items.map((item, index) => (
                  <li key={`contenttext${index}`}>{item.list_item}</li>
                ))}
              </ul>
            </div>
          </div>
          <GatsbyImage
            image={primary.image.gatsbyImageData}
            alt={primary.image.alt||''}
          />
        </div>
      </Section>
    )
  }
}
export default BlockLargePhoto
